//App.mjs
// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/App.mjs

/* global hj */

// **React and Related Imports**
import React, { Suspense, lazy, useState, useEffect, useCallback, useRef, useMemo } from "react";
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import SEO from "./seo/SEO.js";
// **Utility and Context Imports**
import axios from "axios";
import Swal from "sweetalert2";
import { SpeedInsights } from '@vercel/speed-insights/react';
import { useMapContext } from "./contexts/MapContext.js";
import { normalizeAddress } from "./utils/addressUtils.js";
import { fetchAdditionalDetailsByAddress } from "./FetchFunctions/fetchAdditionalDetailsByAddress.jsx";
import { fetchAdditionalDetails } from "./FetchFunctions/fetchAdditionalDetails.jsx";
import { getUserLocation } from "./utils/getUserLocation.js";
import { ScrollLockProvider } from "./contexts/scrollLockContext.js";
import { getCachedPolygonSearch, setCachedPolygonSearch, generateCacheKey } from "./utils/cache.js";
import { updateUrlWithSearchParams } from "./utils/urlUtils.js";

// **Non-Lazy-Loaded Components**
import Navbar from "./components/navbar/NavBar.jsx";
import AuthModal from "./components/Login/AuthModal.jsx"; // Not wrapped with Suspense
import { ErrorProvider } from "./ErrorProvider.js";
import { TutorialProvider } from "./contexts/TutorialContext.js";
import { ViewModeProvider } from "./contexts/ViewModeContext.js";
import { app } from './components/firebase/Firebase.js';
import { isSupported, getAnalytics } from 'firebase/analytics';
import ErrorBoundary from "./utils/ErrorBoundry.jsx"; // Not lazy-loaded
import ProtectedRoute from "./routes/ProtectedRoute.jsx";

// **Lazy-Loaded Components**
const Home = lazy(() => import('./routes/Home.jsx'));
const LoadingSpinner = lazy(() => import("./components/loading/LoadingSpinner.jsx"));
const Listings = lazy(() => import('./routes/Listings.jsx'));
const Dashboard = lazy(() => import('./components/Login/Dashboard/Dashboard.jsx'));
const ProfileSettings = lazy(() => import('./components/Login/Dashboard/ProfileSettings.jsx'));
const PrivacyPolicy = lazy(() => import('./components/pages/companyResources/PrivacyPolicy.jsx'));
const Contact = lazy(() => import('./components/pages/companyResources/Contact.jsx'));
const AboutUs = lazy(() => import('./components/pages/companyResources/AboutUs.jsx'));
const SearchMortgageRates = lazy(() => import('./components/mortgageSection/SearchMortgageRates.jsx'));
const ListWithUs = lazy(() => import('./components/pages/sellerResources/ListWithUs.jsx'));
const HomeValue = lazy(() => import('./components/pages/sellerResources/HomeValue.jsx'));
const MortgageCalculator = lazy(() => import('./components/mortgageSection/MortgageCalculator.jsx'));
const RefinanceCalculator = lazy(() => import('./components/mortgageSection/RefinanceCalculator.jsx'));
const InvestmentCalculator = lazy(() => import('./components/pages/investorResources/InvestmentCalculator.jsx'));
const RenterResources = lazy(() => import('./components/pages/renterResources/RenterResources.jsx'));
const BuyerResources = lazy(() => import('./components/pages/buyerResources/BuyerResources.jsx'));
const SellerResources = lazy(() => import('./components/pages/sellerResources/SellerResources.jsx'));
const SellerAgentFinderForm = lazy(() => import('./components/pages/sellerResources/SellerAgentFinderForm.jsx'));
const SellerAgentFinderLanding = lazy(() => import('./components/pages/sellerResources/SellerAgentFinderLanding.jsx'));
const InvestorResources = lazy(() => import('./components/pages/investorResources/InvestorResources.jsx'));
const AgentFinderForm = lazy(() => import('./components/pages/buyerResources/AgentFinderForm.jsx'));
const AgentFinderLanding = lazy(() => import('./components/pages/buyerResources/AgentFinderLanding.jsx'));
const BookingPage = lazy(() => import('./components/pages/companyResources/BookingPage.jsx'));
const Reset = lazy(() => import('./components/Login/Reset.js'));
const BlogList = lazy(() => import('./components/pages/blogs/BlogList.jsx'));
const BlogPostDetail = lazy(() => import('./components/pages/blogs/BlogPostDetail.jsx'));
const FeaturedListings = lazy(() => import('./components/navbar/FeaturedListings.jsx'));
const SearchLenders = lazy(() => import('./components/mortgageSection/SearchLenders.jsx'));
const RentalMarketTrends = lazy(() => import('./components/pages/investorResources/RentalMarketTrends.jsx'));
const SiteFooter = lazy(() => import('./components/footer/SiteFooter.jsx'));
const SitemapPage = lazy(() => import("./components/pages/companyResources/SitemapPage.jsx"));
const TermsOfService = lazy(() => import("./components/pages/companyResources/TermsOfService.jsx"));
const GoogleAuthCallback = lazy(() => import('./components/Login/GoogleAuthCallback.jsx'));
function App({ initialLoadingDone, startProgress, completeProgress, updateProgress, loading, progress }) {
  const [isAnalyticsInitialized, setIsAnalyticsInitialized] = useState(false);
  const [selectedZpid, setSelectedZpid] = useState(null);
  const [listings, setListings] = useState([]);
  const [isDataReady, setIsDataReady] = useState(false);
  const [urlTriggeredUpdate, setUrlTriggeredUpdate] = useState(false);

  const fetchTriggeredRef = useRef(false);
  const lastFetchedSearchRef = useRef(null);
  const prevSearchTypeRef = useRef();
  const [searchCounter, setSearchCounter] = useState(0);
  const [userHasInteracted, setUserHasInteracted] = useState(false);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [cachedPropertyDetails, setCachedPropertyDetails] = useState(null);

  const [fetchInProgress, setFetchInProgress] = useState(false);
  const [shouldOpenModalOnLoad, setShouldOpenModalOnLoad] = useState(false);

  useEffect(() => {
    setCurrentProgress(progress);
  }, [progress]);


  useEffect(() => {
    async function setLocationForAnalyticsAndHotjar() {
      try {
        // Check if geolocation is supported by the browser
        if (navigator.geolocation) {
          const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });

          // Function to fetch and send the location data
          const fetchAndSendLocation = async () => {
            try {
              const location = await getUserLocation();
              if (location) {
                // Hotjar location tagging
                hj('tagRecording', [
                  `city:${location.city}`,
                  `state:${location.state}`,
                  `country:${location.country}`,
                  `postalCode:${location.postalCode}`,
                  `timeZone:${location.timeZone}`,
                  `latitude:${location.latitude}`,
                  `longitude:${location.longitude}`,
                  `metroCode:${location.metroCode}`
                ]);

                // GA4 location detection
                if (typeof window.gtag === 'function') {
                  window.gtag('event', 'location_detected', {
                    'user_city': location.city,
                    'user_state': location.state,
                    'user_country': location.country,
                    'user_postal_code': location.postalCode,
                    'user_time_zone': location.timeZone,
                    'user_latitude': location.latitude,
                    'user_longitude': location.longitude,
                    'user_metro_code': location.metroCode,
                    'dimension1': location.city,
                    'dimension2': location.state,
                  });
                } else {
                  console.warn('gtag is not available');
                }
              } else {
                console.warn('Location data is not available');
              }
            } catch (error) {
              console.error('Location detection failed:', error);
            }
          };

          // If the permission is already granted, fetch the location immediately
          if (permissionStatus.state === 'granted') {
            fetchAndSendLocation();
          }

          // Handle permission changes: listen for when the user grants or denies permission
          permissionStatus.onchange = () => {
            if (permissionStatus.state === 'granted') {
              fetchAndSendLocation();
            } else if (permissionStatus.state === 'denied') {
              console.warn('Location permission denied by user');
            }
          };
        } else {
          console.warn('Geolocation is not supported by this browser');
        }
      } catch (error) {
        console.error('Location detection failed:', error);
      }
    }

    setLocationForAnalyticsAndHotjar();
  }, []);





  const [selectedListing, setSelectedListing] = useState(null);
  const [resetData, setResetData] = useState(false);
  const [filters, setFilters] = useState({
    minPrice: "",
    maxPrice: "",
    bedsMin: "",
    bedsMax: "",
    bathsMin: "",
    bathsMax: "",
    sqftMin: "",
    sqftMax: "",
    buildYearMin: "",
    buildYearMax: "",
    lotSizeMin: "",
    lotSizeMax: "",
    daysOn: "",
    daysOnZillow: "",
    status_type: "",
    home_type: "",
    sort: "",
    isOpenHousesOnly: "",
    isComingSoon: "",
    isPendingUnderContract: "",
    isForSaleForeclosure: "",
    isPreForeclosure: "",
    saleByOwner: "",
    saleByAgent: "",
    isParkView: "",
    isWaterView: "",
    isWaterfront: "",
    isCityView: "",
    isMountainView: "",
    page:"1",
  });

  const defaultFilters = useMemo(() => ({
    minPrice: "",
    maxPrice: "",
    bedsMin: "",
    bedsMax: "",
    bathsMin: "",
    bathsMax: "",
    sqftMin: "",
    sqftMax: "",
    buildYearMin: "",
    buildYearMax: "",
    lotSizeMin: "",
    lotSizeMax: "",
    daysOn: "",
    daysOnZillow: "",
    status_type: "",
    home_type: "",
    sort: "newest",
    isOpenHousesOnly: "",
    isComingSoon: "",
    isPendingUnderContract: "",
    isForSaleForeclosure: "",
    isPreForeclosure: "",
    saleByOwner: "",
    saleByAgent: "",
    isParkView: "",
    isWaterView: "",
    isWaterfront: "",
    isCityView: "",
    isMountainView: "",
    page: "1",
  }), []);

  const [totalResultCount, setTotalResultCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1); // Default to 1 if not defined

  const [useQuery, setUseQuery] = useState("");
  const length = listings?.length;
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [activeTab, setActiveTab] = useState(''); // default to 'login'

  const host = "zillow-com1.p.rapidapi.com";
  const key = process.env.REACT_APP_API_KEY_RAPID_API_KEY;


  const { polygonCoordinates, updatePolygonCoordinates, searchType, setSearchType } = useMapContext();

  const [filteredData, setFilteredData] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState(filters);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const zpid = queryParams.get('zpid');
  const address = queryParams.get('address');
  const addressTriggered = queryParams.get('addressTriggered');
  const searchQuery = queryParams.get("location") || "";

  useEffect(() => {
    if (!isAnalyticsInitialized) {
      isSupported().then((supported) => {
        if (supported) {
          const analytics = getAnalytics(app);
        } else {
          console.warn('Firebase Analytics not supported');
        }
      });
      setIsAnalyticsInitialized(true);
    }
  }, [isAnalyticsInitialized]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const authParam = params.get('auth');
  
    if (authParam === 'login' || authParam === 'register') {
      setShowAuthModal(true);
    } else {
      setShowAuthModal(false);
    }
  }, [location]); // Monitor the entire location object, not just location.search
  

  const closeAuthModal = useCallback(() => {
/*     console.log("Closing auth modal..."); */
  
    setShowAuthModal(false);
  
    setTimeout(() => {
      const params = new URLSearchParams(location.search);
      params.delete('auth');
/*       console.log("Updated URL params:", params.toString()); */
  
      // Determine the new URL to navigate to
      let newUrl = `${location.pathname}${params.toString() ? `?${params.toString()}` : ''}`;
  
      // If the current path is /login or /register, redirect explicitly to home
      if (location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/dashboard') {
/*         console.log("Redirecting to home page..."); */
        navigate('/', { replace: true });
      } else {
/*         console.log("Navigating to:", newUrl); */
        navigate(newUrl, { replace: true });
      }
    }, 0);
  }, [navigate, location]);


  //PRODUCTION
  const fetchListings = useCallback(
    async (
      searchQuery,
      polygonCoords = null,
      newSearchType,
      triggeredBy = "",
      updatedFilters = null,
      signal = null
    ) => {

      const startTime = performance.now(); // Start timer for performance tracking
      const actualPolygonCoords = polygonCoords || polygonCoordinates; // From context

      // Reset `prevSearchTypeRef` if switching from address to location search
      if (newSearchType === "location" && prevSearchTypeRef.current === "address") {
        prevSearchTypeRef.current = ""; // Reset previous search type for a new location search
      }

      // Simplified parameter validation
      if (
        (newSearchType === "polygon" && (!actualPolygonCoords || actualPolygonCoords.length === 0)) ||
        (newSearchType === "location" && (!searchQuery || searchQuery.trim() === ""))
      ) {
        console.error("Invalid search parameters provided.");
        Swal.fire({
          icon: "error",
          title: "Invalid Search",
          text: "Please provide valid search parameters and try again.",
        });
        completeProgress();

        // End timer and calculate duration for logging purposes.
        const endTime = performance.now();
        return;
      }

      // Prevent duplicate calls or race conditions
      if (signal?.aborted) {
        return;
      }

      // Proceed with existing logic
      startProgress(); // Start loading

      // Merge filters
      let effectiveFilters = {
        ...defaultFilters, // Assuming defaultFilters is defined elsewhere
        ...filters, // Assuming filters is from state/context
        ...(updatedFilters || {}),
      };

      // Parse query params from URL and merge them with effectiveFilters
      const urlSearchParams = new URLSearchParams(location.search);
      urlSearchParams.forEach((value, key) => {
        effectiveFilters[key] = value;
      });

      // Set default status_type if not provided
      effectiveFilters.status_type = effectiveFilters.status_type || "ForSale";
      effectiveFilters.page = effectiveFilters.page || "1";

      // Handle polygon-specific logic
      let formattedPolygonCoords = null;

      if (
        typeof actualPolygonCoords === "string" &&
        actualPolygonCoords.length > 0
      ) {
        formattedPolygonCoords = actualPolygonCoords;
      } else if (
        Array.isArray(actualPolygonCoords) &&
        actualPolygonCoords.length > 0
      ) {
        // Convert array to formatted string (assuming [lng, lat] pairs)
        formattedPolygonCoords = actualPolygonCoords
          .map((coord) => `${coord[0]} ${coord[1]}`)
          .join(",");
      }

      // Validate formattedPolygonCoords
      if (newSearchType === "polygon" && !formattedPolygonCoords) {
        console.error(
          "Formatted polygon coordinates are invalid:",
          actualPolygonCoords
        );
        completeProgress();

        // End timer and calculate duration for logging purposes.
        const endTime = performance.now();
        return;
      }

      // **Generate Comprehensive Cache Key**
      let cacheKey = null;
      if (newSearchType === "polygon" && formattedPolygonCoords) {
        cacheKey = generateCacheKey(formattedPolygonCoords, effectiveFilters);
      }

      // **Check Cache for Polygon Searches**
      if (newSearchType === "polygon" && cacheKey) {
        const cachedPolygonData = getCachedPolygonSearch(
          formattedPolygonCoords,
          effectiveFilters
        );

        // Modify condition to ensure we refetch if filters are changed.
        if (cachedPolygonData && JSON.stringify(cachedPolygonData.filters) === JSON.stringify(effectiveFilters)) {
          setListings(cachedPolygonData.results);
          completeProgress();

          const endTime = performance.now();
          return; // Exit early if we can use the cached data and filters haven't changed.
        }
      }

      // **Set the search type in context**
      setSearchType(newSearchType); // Assuming setSearchType is from context/state

      setResetData(true); // Assuming setResetData is from state
      const previousSearchType = prevSearchTypeRef.current; // Assuming prevSearchTypeRef is a useRef
      prevSearchTypeRef.current = newSearchType;

      // Adjust effectiveFilters based on search type
      if (newSearchType === "polygon") {
        if (formattedPolygonCoords) {
          effectiveFilters.polygon = formattedPolygonCoords;
          delete effectiveFilters.address;
          delete effectiveFilters.location;
        }
      } else if (newSearchType === "location") {
        if (searchQuery) {
          effectiveFilters.location = searchQuery;
          delete effectiveFilters.polygon;
        }
      }

      // Remove empty or irrelevant parameters from filters
      Object.keys(effectiveFilters).forEach((key) => {
        if (
          !effectiveFilters[key] ||
          (effectiveFilters.status_type === "ForRent" &&
            [
              "saleByAgent",
              "saleByOwner",
              "isForSaleForeclosure",
              "isNewConstruction",
              "isComingSoon",
              "isAuction",
              "isPendingUnderContract",
              "otherListings",
            ].includes(key))
        ) {
          delete effectiveFilters[key];
        }
      });

      // Adjust params for the API request
      const params = { ...effectiveFilters };

      // Further adjust params based on search type
      if (newSearchType === "polygon" && formattedPolygonCoords) {
        params.polygon = formattedPolygonCoords;
        delete params.address;
        delete params.location;
      } else if (newSearchType === "location") {
        delete params.polygon;
      }

      // Remove any params that are empty strings
      Object.keys(params).forEach((key) => {
        if (params[key] === "") {
          delete params[key];
        }
      });


      try {
        let details;
        if (newSearchType === "address") {
          details = await fetchAdditionalDetailsByAddress(
            normalizeAddress(searchQuery)
          );
        } else if (newSearchType === "zpid") {
          details = await fetchAdditionalDetails(searchQuery);
        } else {
          // **Production API Call Using Server Endpoint**
          const apiUrl = "/api/extendedSearch"; // Production API endpoint


          const response = await axios.get(apiUrl, { params, signal });


          if (response.status !== 200) {
            throw new Error(`Server error: ${response.statusText}`);
          }

          const responseData = response.data;

          const totalResultCountFromResponse = responseData.totalResultCount;

          setTotalResultCount(totalResultCountFromResponse);

          const totalPagesFromResponse = responseData.totalPages;

          setTotalPages(totalPagesFromResponse);

          if (
            Array.isArray(responseData.props) &&
            responseData.props.length > 0
          ) {
            const validProps = responseData.props.filter(
              (property) =>
                property.latitude !== null && property.longitude !== null
            );

            // **Cache the results by the comprehensive cacheKey**
            if (newSearchType === "polygon" && cacheKey) {
              setCachedPolygonSearch(
                formattedPolygonCoords,
                effectiveFilters,
                validProps
              );
            }

            setListings(validProps); // Assuming setListings is from state

            validProps.forEach((_, i) => {
              const percentage = ((i + 1) / validProps.length) * 100;
              updateProgress(percentage); // Assuming updateProgress is from state
            });

            completeProgress();

            // End timer and calculate duration for logging purposes.
            const endTime = performance.now();
            return validProps;
          } else if (responseData.zpid) {
            details = responseData;
          }
        }

        if (details) {
          setListings([details]); // Assuming setListings is from state
          setSelectedListing(details); // Assuming setSelectedListing is from state
          completeProgress();

          const newUrl = updateUrlWithSearchParams(
            params,
            searchQuery,
            newSearchType,
            formattedPolygonCoords || undefined,
            details.zpid,
            defaultFilters
          ); // Ensure this function is correctly implemented
          navigate(newUrl, { replace: true }); // Assuming navigate is from react-router

          // End timer and calculate duration for logging purposes.
          const endTime = performance.now();
          return [details];
        } else {
          setListings([]);
          Swal.fire({
            icon: "error",
            title: "No Results Found",
            text: "No properties found! Adjust your filters and try again, or search a new location.",
          });

          const newUrl = updateUrlWithSearchParams(
            params,
            searchQuery,
            newSearchType,
            formattedPolygonCoords || undefined,
            undefined,
            defaultFilters
          );
          navigate(newUrl, { replace: true });
        }
      } catch (error) {
        if (error.name === "AbortError") {
        } else {
          console.error(`Error in API call (triggered by ${triggeredBy}):`, error);
        }
        setListings([]);

        const newUrl = updateUrlWithSearchParams(
          params,
          searchQuery,
          newSearchType,
          formattedPolygonCoords || undefined,
          undefined,
          defaultFilters
        );
        navigate(newUrl, { replace: true });
      } finally {
        completeProgress();
        const endTime = performance.now(); // End timer for performance tracking
      }
      setSearchCounter((prev) => prev + 1);
      setUserHasInteracted(false);
    },
    [
      filters,
      polygonCoordinates,
      updatePolygonCoordinates,
      startProgress,
      updateProgress,
      completeProgress,
      defaultFilters,
      setSelectedListing,
      useQuery,
      location.search,
      navigate,
    ]
  );


/* 
  // Testing 
  const fetchListings = useCallback(
    async (
      searchQuery,
      polygonCoords = null,
      newSearchType,
      triggeredBy = "",
      updatedFilters = null,
      signal = null
    ) => {
      console.log("Dependencies Changed - FetchListings triggered");
      console.log("searchQuery:", searchQuery);
      console.log("polygonCoords:", polygonCoords);
      console.log("newSearchType:", newSearchType);
      console.log("triggeredBy:", triggeredBy);
      console.log("updatedFilters:", updatedFilters);
      console.log("signal:", signal);
      const startTime = performance.now(); // Start timer for performance tracking
      const actualPolygonCoords = polygonCoords || polygonCoordinates; // From context
      console.log("Actual Polygon Coordinates:", actualPolygonCoords);
      console.log("fetchListings called with:", {
        searchQuery,
        polygonCoords: actualPolygonCoords,
        searchType: newSearchType,
        triggeredBy,
        updatedFilters,
      });
  
      // Reset `prevSearchTypeRef` if switching from address to location search
      if (newSearchType === "location" && prevSearchTypeRef.current === "address") {
        prevSearchTypeRef.current = ""; // Reset previous search type for a new location search
      }
  
      // Simplified parameter validation
      if (
        (newSearchType === "polygon" && (!actualPolygonCoords || actualPolygonCoords.length === 0)) ||
        (newSearchType === "location" && (!searchQuery || searchQuery.trim() === ""))
      ) {
        console.error("Invalid search parameters provided.");
        Swal.fire({
          icon: "error",
          title: "Invalid Search",
          text: "Please provide valid search parameters and try again.",
        });
        completeProgress();
  
        // End timer and calculate duration for logging purposes.
        const endTime = performance.now();
        console.log(`Fetch took ${endTime - startTime} ms`);
        return;
      }
  
      // Prevent duplicate calls or race conditions
      if (signal?.aborted) {
        console.log("Fetch aborted before execution");
        return;
      }
  
      // Proceed with existing logic
      console.log("fetchListings called with:", { searchQuery, polygonCoords, searchType: newSearchType, triggeredBy, updatedFilters });
      startProgress(); // Start loading
  
      // Merge filters
      let effectiveFilters = {
        ...defaultFilters, // Assuming defaultFilters is defined elsewhere
        ...filters, // Assuming filters is from state/context
        ...(updatedFilters || {}),
      };
  
      // Parse query params from URL and merge them with effectiveFilters
      const urlSearchParams = new URLSearchParams(location.search);
      urlSearchParams.forEach((value, key) => {
        effectiveFilters[key] = value;
      });
  
      // Set default status_type if not provided
      effectiveFilters.status_type = effectiveFilters.status_type || "ForSale";
      effectiveFilters.page = effectiveFilters.page || "1";
  
      console.log("Effective Filters after merging:", effectiveFilters);
  
      // Handle polygon-specific logic
      let formattedPolygonCoords = null;
  
      if (
        typeof actualPolygonCoords === "string" &&
        actualPolygonCoords.length > 0
      ) {
        formattedPolygonCoords = actualPolygonCoords;
      } else if (
        Array.isArray(actualPolygonCoords) &&
        actualPolygonCoords.length > 0
      ) {
        // Convert array to formatted string (assuming [lng, lat] pairs)
        formattedPolygonCoords = actualPolygonCoords
          .map((coord) => `${coord[0]} ${coord[1]}`)
          .join(",");
      }
  
      // Validate formattedPolygonCoords
      if (newSearchType === "polygon" && !formattedPolygonCoords) {
        console.error(
          "Formatted polygon coordinates are invalid:",
          actualPolygonCoords
        );
        completeProgress();
  
        // End timer and calculate duration for logging purposes.
        const endTime = performance.now();
        console.log(`Fetch took ${endTime - startTime} ms`);
        return;
      }
  
      // **Generate Comprehensive Cache Key**
      let cacheKey = null;
      if (newSearchType === "polygon" && formattedPolygonCoords) {
        cacheKey = generateCacheKey(formattedPolygonCoords, effectiveFilters);
      }
  
      // **Check Cache for Polygon Searches**
      if (newSearchType === "polygon" && cacheKey) {
        const cachedPolygonData = getCachedPolygonSearch(
          formattedPolygonCoords,
          effectiveFilters
        );
      
        // Modify condition to ensure we refetch if filters are changed.
        if (cachedPolygonData && JSON.stringify(cachedPolygonData.filters) === JSON.stringify(effectiveFilters)) {
          console.log(
            "Cache hit for polygon search with same filters:",
            cachedPolygonData
          );
          setListings(cachedPolygonData.results);
          completeProgress();
      
          const endTime = performance.now();
          console.log(`Fetch took ${endTime - startTime} ms`);
          return; // Exit early if we can use the cached data and filters haven't changed.
        }
      }
  
      // **Set the search type in context**
      setSearchType(newSearchType); // Assuming setSearchType is from context/state
  
      setResetData(true); // Assuming setResetData is from state
      const previousSearchType = prevSearchTypeRef.current; // Assuming prevSearchTypeRef is a useRef
      prevSearchTypeRef.current = newSearchType;
  
      // Adjust effectiveFilters based on search type
      if (newSearchType === "polygon") {
        if (formattedPolygonCoords) {
          effectiveFilters.polygon = formattedPolygonCoords;
          delete effectiveFilters.address;
          delete effectiveFilters.location;
        }
      } else if (newSearchType === "location") {
        if (searchQuery) {
          effectiveFilters.location = searchQuery;
          delete effectiveFilters.polygon;
        }
      }
  
      // Remove empty or irrelevant parameters from filters
      Object.keys(effectiveFilters).forEach((key) => {
        if (
          !effectiveFilters[key] ||
          (effectiveFilters.status_type === "ForRent" &&
            [
              "saleByAgent",
              "saleByOwner",
              "isForSaleForeclosure",
              "isNewConstruction",
              "isComingSoon",
              "isAuction",
              "isPendingUnderContract",
              "otherListings",
            ].includes(key))
        ) {
          delete effectiveFilters[key];
        }
      });
  
      // Adjust params for the API request
      const params = { ...effectiveFilters };
  
      // Further adjust params based on search type
      if (newSearchType === "polygon" && formattedPolygonCoords) {
        params.polygon = formattedPolygonCoords;
        delete params.address;
        delete params.location;
      } else if (newSearchType === "location") {
        delete params.polygon;
      }
  
      // Remove any params that are empty strings
      Object.keys(params).forEach((key) => {
        if (params[key] === "") {
          delete params[key];
        }
      });
  
      console.log("API Params for Fetch:", params);
  
      try {
        let details;
        if (newSearchType === "address") {
          details = await fetchAdditionalDetailsByAddress(
            normalizeAddress(searchQuery)
          );
        } else if (newSearchType === "zpid") {
          details = await fetchAdditionalDetails(searchQuery);
        } else {
          // **Fetch Directly Using Axios**
          const apiUrl =
            "https://zillow-com1.p.rapidapi.com/propertyExtendedSearch"; // Replace with your testing API endpoint
  
          console.log(
            `Making Axios request to Zillow API at ${apiUrl} with params:`,
            params
          );
  
          const response = await axios.request({
            method: "GET",
            url: apiUrl,
            params: params,
            headers: {
              "x-rapidapi-key": key, // Ensure 'key' is defined and accessible
              "x-rapidapi-host": host, // Ensure 'host' is defined and accessible
            },
            signal, // Pass the AbortSignal here for request cancellation
          });
  
          console.log("App - API Response:", response.data);
  
          const totalResultCountFromResponse = response.data.totalResultCount;
  
          setTotalResultCount(totalResultCountFromResponse); // Assuming setTotalResultCount is from state
          
          const totalPagesFromResponse = response.data.totalPages;
  
          setTotalPages(totalPagesFromResponse); // Assuming setTotalResultCount is from state
  
          if (
            Array.isArray(response.data.props) &&
            response.data.props.length > 0
          ) {
            const validProps = response.data.props.filter(
              (property) =>
                property.latitude !== null && property.longitude !== null
            );
  
            // **Cache the results by the comprehensive cacheKey**
            if (newSearchType === "polygon" && cacheKey) {
              setCachedPolygonSearch(
                formattedPolygonCoords,
                effectiveFilters,
                validProps
              );
            }
  
            setListings(validProps); // Assuming setListings is from state
  
            validProps.forEach((_, i) => {
              const percentage = ((i + 1) / validProps.length) * 100;
              updateProgress(percentage); // Assuming updateProgress is from state
            });
  
            completeProgress();
  
            // End timer and calculate duration for logging purposes.
            const endTime = performance.now();
            console.log(`Fetch took ${endTime - startTime} ms`);
            console.log("fetchListings - Listings set:", validProps);
            return validProps;
          } else if (response.data.zpid) {
            details = response.data;
          }
        }
  
        if (details) {
          setListings([details]); // Assuming setListings is from state
          setSelectedListing(details); // Assuming setSelectedListing is from state
          completeProgress();
  
          const newUrl = updateUrlWithSearchParams(
            params,
            searchQuery,
            newSearchType,
            formattedPolygonCoords || undefined,
            details.zpid,
            defaultFilters
          ); // Ensure this function is correctly implemented
          navigate(newUrl, { replace: true }); // Assuming navigate is from react-router
  
          // End timer and calculate duration for logging purposes.
          const endTime = performance.now();
          console.log(`Fetch took ${endTime - startTime} ms`);
          console.log("fetchListings - Single property set:", details);
          return [details];
        } else {
          setListings([]);
          Swal.fire({
            icon: "error",
            title: "No Results Found",
            text: "No properties found! Adjust your filters and try again, or search a new location.",
          });
  
          const newUrl = updateUrlWithSearchParams(
            params,
            searchQuery,
            newSearchType,
            formattedPolygonCoords || undefined,
            undefined,
            defaultFilters
          );
          navigate(newUrl, { replace: true });
        }
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error(`Error in API call (triggered by ${triggeredBy}):`, error);
        }
        setListings([]);
  
        const newUrl = updateUrlWithSearchParams(
          params,
          searchQuery,
          newSearchType,
          formattedPolygonCoords || undefined,
          undefined,
          defaultFilters
        );
        navigate(newUrl, { replace: true });
      } finally {
        completeProgress();
        const endTime = performance.now(); // End timer for performance tracking
        console.log(`Fetch took ${endTime - startTime} ms`); // Log the total duration
      }
      setSearchCounter((prev) => prev + 1);
      setUserHasInteracted(false);
    },
    [
      filters,
      polygonCoordinates,
      updatePolygonCoordinates,
      startProgress,
      updateProgress,
      completeProgress,
      defaultFilters,
      setSelectedListing,
      useQuery,
      location.search,
      navigate,
      key, // Ensure 'key' is included if it's from props or state
      host, // Ensure 'host' is included if it's from props or state
    ]
  );
 */

  const fetchDetails = async (zpid, address) => {
    let details = null;
  
    // Try to fetch details by zpid if it exists
    if (zpid) {
      try {
        /* console.log(`Attempting to fetch details by zpid: ${zpid}`); */
        details = await fetchAdditionalDetails(zpid);
      } catch (error) {
        console.error(`Failed to fetch details by zpid: ${zpid}`, error);
      }
    }
  
    // If zpid fetch fails or returns null, try fetching by address (ensure the correct value is used)
    if (!details && address) {
      try {
/*         console.log(`Attempting to fetch details by address: ${address}`); */
        details = await fetchAdditionalDetailsByAddress(address);
      } catch (error) {
        console.error(`Failed to fetch details by address: ${address}`, error);
      }
    }
  
    // If neither fetch succeeded, display an error
    if (!details) {
      console.warn("No details fetched from either zpid or address.");
      Swal.fire({
        title: 'Error!',
        html: 'Sorry, we had some trouble fetching the property details. Please try again later or contact support.',
        icon: 'error',
      });
    }
  
    // Return the fetched details (or null if none succeeded)
    return details;
  };


useEffect(() => {
  const controller = new AbortController();
  const signal = controller.signal;
  const queryParams = new URLSearchParams(location.search);
  const zpid = queryParams.get('zpid');
  const address = queryParams.get('address');
  const locationParam = queryParams.get('location');
  const polygonCoords = queryParams.get('polygon');

  if (
    urlTriggeredUpdate &&
    !fetchInProgress &&
    location.search !== lastFetchedSearchRef.current
  ) {
    // Update the ref to the current search to prevent duplicate fetches
    lastFetchedSearchRef.current = location.search;

    if (zpid || address) {
      startProgress();
      setFetchInProgress(true);
      fetchDetails(zpid, address)
        .then((details) => {
          if (details) {
            setListings([details]);
            setSelectedListing(details);
            setSelectedZpid(details.zpid);
            setShouldOpenModalOnLoad(true); // Set the flag to indicate modal should open
          }
        })
        .catch((error) => {
          if (error.name === 'AbortError') {
            // Fetch aborted
          } else {
            console.error("Error fetching details:", error);
          }
        })
        .finally(() => {
          completeProgress();
          setUrlTriggeredUpdate(false);
          setFetchInProgress(false);
          setIsDataReady(true);
          fetchTriggeredRef.current = false;
        });
    } else if (locationParam || polygonCoords) {
      startProgress();
      const searchQuery = locationParam || ''; // Ensure it's a string
      const searchType = locationParam ? 'location' : 'polygon';

      setFetchInProgress(true);
      fetchListings({
        searchQuery,
        polygonCoords,
        newSearchType: searchType,
        triggeredBy: 'urlLoad',
        updatedFilters: null,
        signal,
      })
        .then((data) => {
          completeProgress();
          setUrlTriggeredUpdate(false);
          setFetchInProgress(false);
          setIsDataReady(true);
          fetchTriggeredRef.current = false;
        })
        .catch((error) => {
          if (error.name === 'AbortError') {
            // Fetch aborted
          } else {
            console.error("Error fetching listings:", error);
          }
          completeProgress();
          setUrlTriggeredUpdate(false);
          setFetchInProgress(false);
          setIsDataReady(true);
          fetchTriggeredRef.current = false;
        });
    } else {
      completeProgress(); // Ensure the loader stops
      setUrlTriggeredUpdate(false);
      setFetchInProgress(false);
      setIsDataReady(true);
    }
  }

  // Cleanup function to abort any ongoing requests when the effect is re-triggered
  return () => {
    controller.abort();
    if (fetchInProgress) {
      setFetchInProgress(false);
    }
  };
}, [
  location.search,
  urlTriggeredUpdate,
  fetchInProgress,
  fetchListings,
  fetchDetails,
  startProgress,
  completeProgress,
  shouldOpenModalOnLoad
]);

/* useEffect(() => {
  console.log("Dependency Changes:");
  console.log("filters:", filters);
  console.log("polygonCoordinates:", polygonCoordinates);
  console.log("startProgress:", startProgress);
  console.log("updateProgress:", updateProgress);
  console.log("completeProgress:", completeProgress);
  console.log("defaultFilters:", defaultFilters);
  console.log("setSelectedListing:", setSelectedListing);
  console.log("useQuery:", useQuery);
  console.log("location.search:", location.search);
  console.log("navigate:", navigate);
  console.log("key:", key);
  console.log("host:", host);
}, [
  filters,
  polygonCoordinates,
  startProgress,
  updateProgress,
  completeProgress,
  defaultFilters,
  setSelectedListing,
  useQuery,
  location.search,
  navigate,
  key,
  host,
]);
 */


  const handleClearFilters = useCallback(() => {
    setFilters(defaultFilters);
    setResetData(true);
  }, [defaultFilters]);

  useEffect(() => {
    if (resetData) {
      setListings([]);
      setFilteredData([]);
      setResetData(false);
    }
  }, [resetData]);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
    }
  }, []);

  useEffect(() => {
    const referrer = document.referrer;

    if (referrer.includes("google.com")) {
      const urlParams = new URLSearchParams(window.location.search);
      const initialFilters = {};

      urlParams.forEach((value, key) => {
        initialFilters[key] = value;
      });

      setFilters(initialFilters);
    }
  }, []);



  return (
    <ErrorBoundary>
      <ScrollLockProvider>
        <ViewModeProvider>
          <TutorialProvider>
            <ErrorProvider>
              {/* Header */}
              <header>
                <Navbar
                  closeAuthModal={closeAuthModal}
                  showAuthModal={showAuthModal}
                  setShowAuthModal={setShowAuthModal}
                />
                {showAuthModal && (
                  <AuthModal activeTab={activeTab} onClose={closeAuthModal} />
                )}
              </header>

              <SpeedInsights />

              {/* Main Content */}
              <main id="main-content">
                <div className="mainContent-container">
                  <Routes>
                    {/* Home Route */}
                    <Route
                      path="/"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Willamette Valley Real Estate Agents, Realtors | Spear Real Estate Group"
                              description="Leaders in the real estate marketplace. Search millions of for-sale and rental properties, compare home values, and connect with experienced local professionals at Spear Real Estate Group."
                              canonical="https://www.spearrealestategroup.com/"
                              ogImage="https://www.spearrealestategroup.com/assets/SREG-Logo.webp" // Optional
                              keywords="real estate, property, home, sale, buy, realtor, Willamette Valley"
                            />
                            <Home
                              fetchListings={fetchListings}
                              listings={listings}
                              setSearchType={setSearchType}
                              filters={filters}
                              useQuery={useQuery}
                              setFilters={setFilters}
                              setAppliedFilters={setAppliedFilters}
                              handleClearFilters={handleClearFilters}
                              searchType={searchType}
                              setListings={setListings}
                              setFilteredData={setFilteredData}
                              fetchAdditionalDetails={fetchAdditionalDetails}
                              defaultFilters={defaultFilters}
                              updateUrlWithSearchParams={updateUrlWithSearchParams}
                              setUseQuery={setUseQuery}
                              appliedFilters={appliedFilters}
                              urlTriggeredUpdate={urlTriggeredUpdate}
                              setUrlTriggeredUpdate={setUrlTriggeredUpdate}
                              startProgress={startProgress}
                              completeProgress={completeProgress}
                              updateProgress={updateProgress}
                              loading={initialLoadingDone}
                            />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Secondary '/home' route */}
                    <Route
                      path="/home"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Willamette Valley Real Estate Agents, Realtors | Spear Real Estate Group"
                              description="Leaders in the real estate marketplace. Search millions of for-sale and rental properties, compare home values, and connect with experienced local professionals at Spear Real Estate Group."
                              canonical="https://www.spearrealestategroup.com/"
                              ogImage="https://www.spearrealestategroup.com/assets/SREG-Logo.webp" // Optional
                              keywords="real estate, property, home, sale, buy, realtor, Willamette Valley"
                            />
                            <Home
                              fetchListings={fetchListings}
                              listings={listings}
                              setSearchType={setSearchType}
                              filters={filters}
                              useQuery={useQuery}
                              setFilters={setFilters}
                              setAppliedFilters={setAppliedFilters}
                              handleClearFilters={handleClearFilters}
                              searchType={searchType}
                              setListings={setListings}
                              setFilteredData={setFilteredData}
                              fetchAdditionalDetails={fetchAdditionalDetails}
                              defaultFilters={defaultFilters}
                              updateUrlWithSearchParams={updateUrlWithSearchParams}
                              setUseQuery={setUseQuery}
                              appliedFilters={appliedFilters}
                              urlTriggeredUpdate={urlTriggeredUpdate}
                              setUrlTriggeredUpdate={setUrlTriggeredUpdate}
                              startProgress={startProgress}
                              completeProgress={completeProgress}
                              updateProgress={updateProgress}
                              loading={initialLoadingDone}
                            />
                          </>
                        </Suspense>
                      }
                    />


                    {/* Listings Route */}
                    <Route
                      path="/listings/:locationOrZpid?/:zpid?/"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <Listings
                            startProgress={startProgress}
                            completeProgress={completeProgress}
                            updateProgress={updateProgress}
                            loading={loading}
                            progress={progress}
                            shouldOpenModalOnLoad={shouldOpenModalOnLoad}
                            setShouldOpenModalOnLoad={setShouldOpenModalOnLoad}
                            setUserHasInteracted={setUserHasInteracted}
                            queryParams={{ zpid, address, addressTriggered }}
                            selectedListing={selectedListing}
                            setSelectedListing={setSelectedListing}
                            selectedZpid={selectedZpid}
                            setSelectedZpid={setSelectedZpid}
                            cachedPropertyDetails={cachedPropertyDetails}
                            setCachedPropertyDetails={setCachedPropertyDetails}
                            isDataReady={isDataReady}
                            setIsDataReady={setIsDataReady}
                            urlTriggeredUpdate={urlTriggeredUpdate}
                            setUrlTriggeredUpdate={setUrlTriggeredUpdate}
                            updateUrlWithSearchParams={updateUrlWithSearchParams}
                            fetchListings={fetchListings}
                            listings={listings}
                            filters={filters}
                            setFilters={setFilters}
                            useQuery={useQuery}
                            setUseQuery={setUseQuery}
                            appliedFilters={appliedFilters}
                            setAppliedFilters={setAppliedFilters}
                            handleClearFilters={handleClearFilters}
                            searchType={searchType}
                            setSearchType={setSearchType}
                            totalResultCount={totalResultCount}
                            setTotalResultCount={setTotalResultCount}
                            totalPages={totalPages}
                            setTotalPages={setTotalPages}
                            setListings={setListings}
                            setFilteredData={setFilteredData}
                            fetchAdditionalDetails={fetchAdditionalDetails}
                            defaultFilters={defaultFilters}
                          />
                        </Suspense>
                      }
                    />

                    {/* Google Auth Callback Route */}
                    <Route
                      path="/auth/google/callback"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <GoogleAuthCallback />
                        </Suspense>
                      }
                    />

                    {/* Reset Route */}
                    <Route
                      path="/reset"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <Reset />
                        </Suspense>
                      }
                    />

                    {/* Login Route */}
                    <Route
                      path="login"
                      element={<AuthModal activeTab="login" onClose={closeAuthModal} />}
                    />

                    {/* Register Route */}
                    <Route
                      path="register"
                      element={<AuthModal activeTab="register" onClose={closeAuthModal} />}
                    />

                    {/* Featured Listings Route */}
                    <Route
                      path="/featured-listings"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <FeaturedListings />
                        </Suspense>
                      }
                    />

                    {/* Dashboard Routes */}
                    <Route
                      path="/dashboard"
                      element={
                        <ProtectedRoute>
                          <Suspense fallback={<LoadingSpinner />}>
                            <ProfileSettings to="/dashboard/profilesettings" />
                          </Suspense>
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/dashboard/:tabName"
                      element={
                        <ProtectedRoute>
                          <Suspense fallback={<LoadingSpinner />}>
                            <Dashboard />
                          </Suspense>
                        </ProtectedRoute>
                      }
                    />

                    {/* Contact Route */}
                    <Route
                      path="/contact"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Contact Us - Spear Real Estate Group"
                              description="Get in touch with Spear Real Estate Group."
                              canonical="https://www.spearrealestategroup.com/contact"
                              ogImage="https://www.spearrealestategroup.com/assets/contact-og-image.webp" // Optional
                              keywords="contact, real estate, Spear Real Estate Group"
                            />
                            <Contact />
                          </>
                        </Suspense>
                      }
                    />

                    {/* About Us Route */}
                    <Route
                      path="/about"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="About Us - Spear Real Estate Group"
                              description="Learn more about Spear Real Estate Group."
                              canonical="https://www.spearrealestategroup.com/about"
                              ogImage="https://www.spearrealestategroup.com/assets/about-og-image.webp" // Optional
                              keywords="about us, real estate, Spear Real Estate Group"
                            />
                            <AboutUs />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Privacy Policy Route */}
                    <Route
                      path="/policy"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Privacy Policy - Spear Real Estate Group"
                              description="Read our privacy policy."
                              canonical="https://www.spearrealestategroup.com/policy"
                              ogImage="https://www.spearrealestategroup.com/assets/privacy-og-image.webp" // Optional
                              keywords="privacy policy, real estate, Spear Real Estate Group"
                            />
                            <PrivacyPolicy />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Terms of Service Route */}
                    <Route
                      path="/terms"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Terms of Service - Spear Real Estate Property Finder"
                              description="Read our terms of service."
                              canonical="https://www.spearrealestategroup.com/terms"
                              ogImage="https://www.spearrealestategroup.com/assets/terms-og-image.webp" // Optional
                              keywords="terms of service, real estate, Spear Real Estate Group"
                            />
                            <TermsOfService />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Search Mortgage Rates Route */}
                    <Route
                      path="/rate-search"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Search Mortgage Rates - Spear Real Estate Group"
                              description="View current mortgage rates and compare lenders."
                              canonical="https://www.spearrealestategroup.com/rate-search"
                              ogImage="https://www.spearrealestategroup.com/assets/rate-search-og-image.webp" // Optional
                              keywords="mortgage rates, lenders, real estate, Spear Real Estate Group"
                            />
                            <SearchMortgageRates />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Mortgage Calculator Route */}
                    <Route
                      path="/mortgage-calculator"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Mortgage Calculator - Spear Real Estate Group"
                              description="Calculate your mortgage payments with our mortgage calculator."
                              canonical="https://www.spearrealestategroup.com/mortgage-calculator"
                              ogImage="https://www.spearrealestategroup.com/assets/mortgage-calculator-og-image.webp" // Optional
                              keywords="mortgage calculator, real estate, Spear Real Estate Group"
                            />
                            <MortgageCalculator />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Search Lenders Route */}
                    <Route
                      path="/search-lenders"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Search Lenders - Spear Real Estate Group"
                              description="Find the best lenders with Spear Real Estate Group."
                              canonical="https://www.spearrealestategroup.com/search-lenders"
                              ogImage="https://www.spearrealestategroup.com/assets/search-lenders-og-image.webp" // Optional
                              keywords="search lenders, real estate, Spear Real Estate Group"
                            />
                            <SearchLenders />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Refinance Calculator Route */}
                    <Route
                      path="/refinance"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Refinance Calculator - Spear Real Estate Group"
                              description="Calculate your refinance options with our refinance calculator."
                              canonical="https://www.spearrealestategroup.com/refinance"
                              ogImage="https://www.spearrealestategroup.com/assets/refinance-og-image.webp" // Optional
                              keywords="refinance calculator, real estate, Spear Real Estate Group"
                            />
                            <RefinanceCalculator />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Buyer Resources Route */}
                    <Route
                      path="/buyer-resources"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Buyer Resources - Spear Real Estate Group"
                              description="Find resources for home buyers."
                              canonical="https://www.spearrealestategroup.com/buyer-resources"
                              ogImage="https://www.spearrealestategroup.com/assets/buyer-resources-og-image.webp" // Optional
                              keywords="buyer resources, home buyers, real estate, Spear Real Estate Group"
                            />
                            <BuyerResources />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Agent Finder Form Route */}
                    <Route
                      path="/agent-finder"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Find a Top Buyer Agent"
                              description="Find the best real estate agents with our agent finder."
                              canonical="https://www.spearrealestategroup.com/agent-finder"
                              ogImage="https://www.spearrealestategroup.com/assets/agent-finder-og-image.webp" // Optional
                              keywords="find agent, real estate agents, Spear Real Estate Group"
                            />
                            <AgentFinderForm />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Agent Finder Landing Route */}
                    <Route
                      path="/agent-landing"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Find a Top Buyer Agent"
                              description="Find the best real estate agents with our agent finder landing page."
                              canonical="https://www.spearrealestategroup.com/agent-landing"
                              ogImage="https://www.spearrealestategroup.com/assets/agent-landing-og-image.webp" // Optional
                              keywords="find agent, real estate agents, Spear Real Estate Group"
                            />
                            <AgentFinderLanding />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Renter Resources Route */}
                    <Route
                      path="/renter-resources"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Renter Resources - Spear Real Estate Group"
                              description="Find resources for renters."
                              canonical="https://www.spearrealestategroup.com/renter-resources"
                              ogImage="https://www.spearrealestategroup.com/assets/renter-resources-og-image.webp" // Optional
                              keywords="renter resources, renters, real estate, Spear Real Estate Group"
                            />
                            <RenterResources />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Seller Resources Route */}
                    <Route
                      path="/seller-resources"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Seller Resources - Spear Real Estate Group"
                              description="Find resources for home sellers."
                              canonical="https://www.spearrealestategroup.com/seller-resources"
                              ogImage="https://www.spearrealestategroup.com/assets/seller-resources-og-image.webp" // Optional
                              keywords="seller resources, home sellers, real estate, Spear Real Estate Group"
                            />
                            <SellerResources />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Seller Agent Finder Form Route */}
                    <Route
                      path="/seller-agent-finder"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Find a Top Seller Agent"
                              description="Find the best real estate agents with our agent finder."
                              canonical="https://www.spearrealestategroup.com/seller-agent-finder"
                              ogImage="https://www.spearrealestategroup.com/assets/seller-agent-finder-og-image.webp" // Optional
                              keywords="find seller agent, real estate agents, Spear Real Estate Group"
                            />
                            <SellerAgentFinderForm />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Seller Agent Finder Landing Route */}
                    <Route
                      path="/seller-agent-landing"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Find a Top Seller Agent"
                              description="Find the best real estate agents with our agent finder landing page."
                              canonical="https://www.spearrealestategroup.com/seller-agent-landing"
                              ogImage="https://www.spearrealestategroup.com/assets/seller-agent-landing-og-image.webp" // Optional
                              keywords="find seller agent, real estate agents, Spear Real Estate Group"
                            />
                            <SellerAgentFinderLanding />
                          </>
                        </Suspense>
                      }
                    />

                    {/* List With Us Route */}
                    <Route
                      path="/list-with-us"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="List with Us - Spear Real Estate Group"
                              description="List your home with Spear Real Estate Group."
                              canonical="https://www.spearrealestategroup.com/list-with-us"
                              ogImage="https://www.spearrealestategroup.com/assets/list-with-us-og-image.webp" // Optional
                              keywords="list with us, list home, real estate, Spear Real Estate Group"
                            />
                            <ListWithUs />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Home Value Route */}
                    <Route
                      path="/home-value"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Home Value - Spear Real Estate Group"
                              description="Find out the value of your home."
                              canonical="https://www.spearrealestategroup.com/home-value"
                              ogImage="https://www.spearrealestategroup.com/assets/home-value-og-image.webp" // Optional
                              keywords="home value, real estate, Spear Real Estate Group"
                            />
                            <HomeValue />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Investor Resources Route */}
                    <Route
                      path="/investor-resources"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Investor Resources - Spear Real Estate Group"
                              description="Find resources for investors."
                              canonical="https://www.spearrealestategroup.com/investor-resources"
                              ogImage="https://www.spearrealestategroup.com/assets/investor-resources-og-image.webp" // Optional
                              keywords="investor resources, investors, real estate, Spear Real Estate Group"
                            />
                            <InvestorResources />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Rental Market Trends Route */}
                    <Route
                      path="/rental-market-trends"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Rental Market Trends - Spear Real Estate Group"
                              description="Stay updated with the latest rental market trends."
                              canonical="https://www.spearrealestategroup.com/rental-market-trends"
                              ogImage="https://www.spearrealestategroup.com/assets/rental-market-trends-og-image.webp" // Optional
                              keywords="rental market trends, rentals, real estate, Spear Real Estate Group"
                            />
                            <RentalMarketTrends />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Investment Calculators Route */}
                    <Route
                      path="/investment-calculators"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Investment Calculators - Spear Real Estate Group"
                              description="Calculate your investment options with our calculators."
                              canonical="https://www.spearrealestategroup.com/investment-calculators"
                              ogImage="https://www.spearrealestategroup.com/assets/investment-calculators-og-image.webp" // Optional
                              keywords="investment calculators, investments, real estate, Spear Real Estate Group"
                            />
                            <InvestmentCalculator />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Blog List Route */}
                    <Route
                      path="/blog"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <BlogList />
                        </Suspense>
                      }
                    />

                    {/* Blog Post Detail Route */}
                    <Route
                      path="/blog/:postId"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <BlogPostDetail />
                        </Suspense>
                      }
                    />

                    {/* Sitemap Route */}
                    <Route
                      path="/sitemap"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Sitemap - Spear Real Estate Group"
                              description="Explore the sitemap of Spear Real Estate Group to find all available pages and resources."
                              canonical="https://www.spearrealestategroup.com/sitemap"
                              ogImage="https://www.spearrealestategroup.com/assets/sitemap-og-image.webp" // Optional
                              keywords="sitemap, real estate, Spear Real Estate Group"
                            />
                            <SitemapPage />
                          </>
                        </Suspense>
                      }
                    />

                    {/* Schedule Route */}
                    <Route
                      path="/schedule"
                      element={
                        <Suspense fallback={<LoadingSpinner />}>
                          <>
                            <SEO
                              title="Book a Meeting - Spear Real Estate Group"
                              description="Schedule a meeting with us using our online booking system."
                              canonical="https://www.spearrealestategroup.com/schedule"
                              ogImage="https://www.spearrealestategroup.com/assets/schedule-og-image.webp" // Optional
                              keywords="schedule meeting, booking, real estate, Spear Real Estate Group"
                            />
                            <BookingPage />
                          </>
                        </Suspense>
                      }
                    />
                  </Routes>
                </div>
              </main>

              {/* Footer */}
              <footer>
                <Suspense fallback={<LoadingSpinner />}>
                  <SiteFooter />
                </Suspense>
              </footer>
            </ErrorProvider>
          </TutorialProvider>
        </ViewModeProvider>
      </ScrollLockProvider>
    </ErrorBoundary>
  );
}

export default App;