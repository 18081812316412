import React, { createContext, useState, useEffect, useContext } from 'react';
import { db } from '../components/firebase/Firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import AuthContext from './AuthContext';

export const SavedHomesContext = createContext();

export const SavedHomesProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [savedHomes, setSavedHomes] = useState([]);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const fetchSavedHomes = async () => {
      if (!user) return;

      const userRef = doc(db, 'UserAccounts', user.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const savedHomesData = userDoc.data().savedHomes || [];
        setSavedHomes(savedHomesData);
      }
    };

    if (user && !initialized) {
      fetchSavedHomes();
      setInitialized(true);
    }
  }, [user, initialized]);

  const addHomeToSavedHomes = async (homeDetails) => {
    if (!user) return;
    const userRef = doc(db, 'UserAccounts', user.uid);
    try {
      await updateDoc(userRef, {
        savedHomes: [...savedHomes, homeDetails]
      });
      setSavedHomes(prevHomes => [...prevHomes, homeDetails]);
    } catch (error) {
      console.error('Error adding home to saved homes:', error);
    }
  };

  const removeHomeFromSavedHomes = async (homeZpid) => {
    if (!user) return;
    const userRef = doc(db, 'UserAccounts', user.uid);
    try {
      const updatedHomes = savedHomes.filter(home => home.zpid !== homeZpid);
      await updateDoc(userRef, {
        savedHomes: updatedHomes
      });
      setSavedHomes(updatedHomes);
    } catch (error) {
      console.error('Error removing home from saved homes:', error);
    }
  };

  const updateSavedHomes = async () => {
    if (!user) return;
    const userRef = doc(db, 'UserAccounts', user.uid);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const savedHomesData = userDoc.data().savedHomes || [];
      setSavedHomes(savedHomesData);
    }
  };

  useEffect(() => {
/*     console.log("Saved homes state updated:", savedHomes); */
  }, [savedHomes]);

  return (
    <SavedHomesContext.Provider value={{ savedHomes, addHomeToSavedHomes, removeHomeFromSavedHomes, updateSavedHomes }}>
      {children}
    </SavedHomesContext.Provider>
  );
};
