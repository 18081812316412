// contexts/ScrollLockContext.js
import React, { createContext, useContext } from 'react';
import { stopScroll, restoreScroll } from '../utils/scrollUtils';

const ScrollLockContext = createContext();

export const useScrollLock = () => useContext(ScrollLockContext);

export const ScrollLockProvider = ({ children }) => (
  <ScrollLockContext.Provider value={{ stopScroll, restoreScroll }}>
    {children}
  </ScrollLockContext.Provider>
);
