
//TutorialContext.js
//Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/contexts/TutorialContext.js

import React, { createContext, useContext, useState } from 'react';

const TutorialContext = createContext({
  isTutorialVisible: false,
  tutorialStep: 0,
  setTutorialStep: () => {},
  setTutorialVisible: () => {} // Ensure you have this line
});

export const useTutorial = () => useContext(TutorialContext);

export const TutorialProvider = ({ children }) => {
  const [isTutorialVisible, setTutorialVisible] = useState(() => {
    const tutorialShown = localStorage.getItem('tutorialShown');
    return !tutorialShown; // Show the tutorial if it hasn't been shown before
  });
  const [tutorialStep, setTutorialStep] = useState(0);

  // Define the toggleTutorial function
  const toggleTutorial = () => {
    setTutorialVisible(prevVisible => !prevVisible);
    if (!isTutorialVisible) {
      setTutorialStep(0); // Reset to the first step if the tutorial becomes visible
    }
  };

  return (
    <TutorialContext.Provider value={{ isTutorialVisible, toggleTutorial, tutorialStep, setTutorialStep, setTutorialVisible }}>
      {children}
    </TutorialContext.Provider>
  );
};
// Add this line at the end of TutorialContext.js to export TutorialContext
export { TutorialContext };
