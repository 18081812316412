// getUserLocation.js
// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/utils/getUserLocation.js

export async function getUserLocation() {
    if (navigator.geolocation) {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const { latitude, longitude } = position.coords;
                try {
                    const response = await fetch(`/api/fetchUserLocation?lat=${latitude}&lng=${longitude}`);
                    const data = await response.json();
                    resolve({
                        city: data.city,
                        state: data.state,
                        country: data.country,
                    });
                } catch (error) {
                    reject(error);
                }
            }, (error) => {
                reject(error);
            });
        });
    } else {
        return null;
    }
}
