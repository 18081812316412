//SEO.js
//Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/seo/SEO.js
import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, canonical, ogImage, twitterCard = "summary_large_image", keywords }) => (
  <Helmet>
    {title && <title>{title}</title>}
    {description && <meta name="description" content={description} />}
    {keywords && <meta name="keywords" content={keywords} />}
    {canonical && <link rel="canonical" href={canonical} />}
    {ogImage && <meta property="og:image" content={ogImage} />}
    {ogImage && <meta property="og:image:width" content="1200" />}
    {ogImage && <meta property="og:image:height" content="630" />}
    {twitterCard && <meta name="twitter:card" content={twitterCard} />}
    {canonical && <meta property="og:url" content={canonical} />}
    {title && <meta property="og:title" content={title} />}
    {description && <meta property="og:description" content={description} />}
    {canonical && <meta name="twitter:url" content={canonical} />}
    {title && <meta name="twitter:title" content={title} />}
    {description && <meta name="twitter:description" content={description} />}
    {ogImage && <meta name="twitter:image" content={ogImage} />}
    {/* Add other meta tags as needed */}
  </Helmet>
);

export default SEO;
