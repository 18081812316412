// src/constants/filterParams.js

export const ALL_FILTER_PARAMS = [
  'location', // Added
  'page', // Added
  'minPrice',
  'maxPrice',
  'rentMinPrice', // Added
  'rentMaxPrice', // Added
  'bedsMin',
  'bedsMax',
  'bathsMin',
  'bathsMax',
  'sqftMin',
  'sqftMax',
  'buildYearMin',
  'buildYearMax',
  'lotSizeMin',
  'lotSizeMax',
  'daysOn',
  'soldInLast', // Added
  'status_type',
  'home_type',
  'sort',
  'isOpenHousesOnly',
  'isComingSoon',
  'isPendingUnderContract',
  'isForSaleForeclosure',
  'isPreForeclosure',
  'saleByOwner',
  'saleByAgent',
  'isParkView',
  'isWaterView',
  'isWaterfront',
  'isCityView',
  'isMountainView',
  'isNewConstruction',
  'otherListings',
  'isAcceptingBackupOffers', // Added
  'keywords', // Added
  'isBasementFinished', // Added
  'isBasementUnfinished', // Added
  'hasPool', // Added
  'hasAirConditioning', // Added
  'is3dHome', // Added
  'coordinates', // Added
  'hoa', // Added
  'includeHomesWithNoHoaData', // Added
  'isAuction', // Added
  'schools', // Added
  'schoolsRating', // Added
  'includeUnratedSchools', // Added
  'hasGarage', // Added
  'parkingSpots', // Added
  'isForeclosed', // Added
  'isEntirePlace', // Added
  'isRoom', // Added
  // Remove 'daysOnZillow' if it's not a valid parameter
];
