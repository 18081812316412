//useProgress.js
//Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/components/hooks/useProgress.js

import { useState, useEffect, useCallback, useRef } from 'react';

const useProgress = () => {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const progressRef = useRef(0);

  const startProgress = useCallback(() => {
/*          console.log("useProgress - Progress started");  */
    setLoading(true);
    setProgress(0);
    progressRef.current = 0;
  }, []);

  const updateProgress = useCallback((increment) => {
    progressRef.current = Math.min(progressRef.current + increment, 100);
/*          console.log(`useProgress - Progress updated: ${progressRef.current}%`);  */
    setProgress(progressRef.current);
  }, []);

  const completeProgress = useCallback(() => {
/*          console.log("useProgress - Progress completed");  */
    progressRef.current = 100;
    setProgress(100);
    setLoading(false);
  }, []);

  useEffect(() => {
    return () => {
      setProgress(0);
      setLoading(false);
    };
  }, []);

  return { progress, loading, startProgress, updateProgress, completeProgress };
};

export default useProgress;
