// AuthProvider.js
//Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/contexts/AuthProvider.js

import React, { useState, useEffect } from 'react';
import AuthContext from './AuthContext';
import { auth, db } from '../components/firebase/Firebase';
import { doc, getDoc } from 'firebase/firestore';

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    const fetchUserData = async (firebaseUser) => {
        try {
            const userDocRef = doc(db, "UserAccounts", firebaseUser.uid);
            const userDoc = await getDoc(userDocRef);

            if (!userDoc.exists()) {
                console.error("No user found with UID:", firebaseUser.uid);
                return null;
            }

            const userData = userDoc.data();
            return {
                uid: firebaseUser.uid,
                email: firebaseUser.email,
                ...userData,
            };

        } catch (error) {
            console.error("Error fetching user data:", error);
            throw new Error("Failed to fetch user data.");
        }
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async firebaseUser => {
            if (firebaseUser) {
                const userData = await fetchUserData(firebaseUser);
                setUser(userData);
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);

    const updateUserName = (newUserName) => {
        setUser((currentUser) => ({
            ...currentUser,
            userName: newUserName
        }));
    };

    const updateUser = (updatedFields) => {
        setUser((currentUser) => ({ ...currentUser, ...updatedFields }));
    };

    const contextValue = {
        user,
        isAuthenticated: !!user,
        setUser,
        fetchUserData,
        updateUserName,
        updateUser
    };

/*     console.log("AuthProvider user:", user);
    console.log("AuthProvider isAuthenticated:", !!user); */

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

