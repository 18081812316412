// utils/scrollUtils.js

let originalHtmlOverflow = '';  // To store the original html overflow style
let isScrollLocked = false;  // Track whether scroll is locked

export const stopScroll = () => {
  if (!isScrollLocked) {  // Only stop scroll if it's not already stopped
    originalHtmlOverflow = document.documentElement.style.overflow || '';
    document.documentElement.style.overflow = 'hidden';
    isScrollLocked = true;
  }
};

export const restoreScroll = () => {
  if (isScrollLocked) {  // Only restore scroll if it was previously stopped
    document.documentElement.style.overflow = originalHtmlOverflow || '';
    isScrollLocked = false;
  }
};
