// LoadingSpinner.jsx
// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/components/loading/LoadingSpinner.jsx

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './LoadingSpinner.css';

const LoadingSpinner = ({ duration = 3000, onComplete }) => {
  const [internalProgress, setInternalProgress] = useState(0);

  useEffect(() => {
    const increment = 100 / (duration / 100);
    const interval = setInterval(() => {
      setInternalProgress(prev => {
        const newProgress = prev + increment;
        if (newProgress >= 100) {
          clearInterval(interval);
          if (onComplete) onComplete();
          return 100;
        }
        return newProgress;
      });
    }, 100);

    return () => clearInterval(interval);
  }, [duration, onComplete]);

  const roundedProgress = Math.round(internalProgress);

  return ReactDOM.createPortal(
    <div className="loading-spinner-overlay dark:bg-slate-100 dark:opacity-80">
      <div className="loading-spinner">
        <div className="bowl">
          <div className="inner">
            <div className="fill" style={{ height: `${roundedProgress}%`, transition: 'height 0.8s ease' }}>
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="150px" height="150px" viewBox="0 0 300 300" enableBackground="new 0 0 300 300" xmlSpace="preserve">
                <path className="waveShape" d="M300,300V2.5c0,0-0.6-0.1-1.1-0.1c0,0-25.5-2.3-40.5-2.4c-15,0-40.6,2.4-40.6,2.4
                c-12.3,1.1-30.3,1.8-31.9,1.9c-2-0.1-19.7-0.8-32-1.9c0,0-25.8-2.3-40.8-2.4c-15,0-40.8,2.4-40.8,2.4c-12.3,1.1-30.4,1.8-32,1.9
                c-2-0.1-20-0.8-32.2-1.9c0,0-3.1-0.3-8.1-0.7V300H300z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="progress-text">{roundedProgress}%</div>
      </div>
    </div>,
    document.getElementById('loading-spinner-portal')
  );
};

export default LoadingSpinner;
