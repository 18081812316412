// Login.jsx
// /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/components/Login/Login.jsx

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { logInWithEmailAndPassword, signInWithGoogle, sendPasswordReset } from '../firebase/Firebase.js';
import { getDoc, setDoc, doc, getFirestore, updateDoc } from "firebase/firestore";
import AuthContext from '../../contexts/AuthContext.js';
import { AuthContainer, AuthInput, AuthButton, GoogleButton } from './SharedAuthStyles.js';
import Swal from 'sweetalert2';
import LoadingSpinner from '../loading/LoadingSpinner.jsx';
import useProgress from '../hooks/useProgress.js';
import { TutorialContext } from '../../contexts/TutorialContext.js';

const db = getFirestore();

function Login({ switchToRegister }) {
    const { setTutorialVisible } = useContext(TutorialContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [loginError, setLoginError] = useState("");
    const { progress, loading, startProgress, completeProgress } = useProgress(1500);

    // Validates an email format
    const isValidEmail = (email) => {
        var regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return regex.test(email);
    };

    // Handles post-login actions like user data updates
    const handlePostLogin = async (user) => {
        const userDocRef = doc(db, "UserAccounts", user.uid);
        try {
            const userDoc = await getDoc(userDocRef);
            if (!userDoc.exists()) {
                await setDoc(userDocRef, {
                    name: user.displayName || "",
                    email: user.email,
                    photoURL: user.photoURL,
                    joinDate: new Date(),
                    lastLoggedIn: new Date()
                });
            } else {
                await updateDoc(userDocRef, {
                    lastLoggedIn: new Date()
                });
            }
            navigate("/dashboard/profilesettings");
        } catch (error) {
            console.error("Error handling post-login:", error);
        }
    };

    // Logs in user using email/password
    const handleEmailPasswordLogin = async () => {
        startProgress();
        setLoginError("");
        try {
            const userCredential = await logInWithEmailAndPassword(email, password);
            if (!userCredential) return;

            localStorage.setItem('isLoggedIn', 'true');
            await handlePostLogin(userCredential.user);
            Swal.fire('Success!', 'Signed in successfully!', 'success');
        } catch (error) {
            const errorMessage = getErrorMessage(error.code);
            displayErrorMessage(errorMessage);
            setLoginError(errorMessage);
        } finally {
            completeProgress();
        }
    };

    // Handles Google login
    const handleGoogleLogin = async () => {
        startProgress();
        setLoginError("");
        try {
            const userCredential = await signInWithGoogle();
            if (!userCredential) {
                throw new Error("No user credential returned");
            }
            localStorage.setItem('isLoggedIn', 'true');
            await handlePostLogin(userCredential.user);
            Swal.fire('Success!', 'Signed in successfully!', 'success');
        } catch (error) {
            const errorMessage = getErrorMessage(error.code);
            displayErrorMessage(errorMessage);
        } finally {
            completeProgress();
        }
    };

    // Extracts error message based on error code
    const getErrorMessage = (code) => {
        switch (code) {
            case "auth/user-not-found":
                return 'Sorry, we could not find an account associated with that email. Please try again!';
            case "auth/wrong-password":
            case "auth/invalid-email":
                return 'Sorry! Incorrect login or password. Please try again';
            case "auth/user-disabled":
                return 'This account has been disabled. Please contact support.';
            case "auth/too-many-requests":
                return 'Too many failed login attempts. Please try again later.';
            default:
                return 'An unexpected error occurred. Please try again.';
        }
    };

    // Displays an error toast message using SweetAlert2
    const displayErrorMessage = (message) => {
        Swal.fire({
            icon: 'error',
            title: 'Oops!',
            text: message,
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3500,
            customClass: {
                container: 'custom-swal'
            }
        });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleEmailPasswordLogin();
    };
    
    // Handles password reset using SweetAlert2 prompt
    const handleResetPassword = async (e) => {
        e.preventDefault();
        const inputEmail = await Swal.fire({
            title: 'Reset Password',
            input: 'email',
            inputPlaceholder: 'Enter your email address',
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to write an email!';
                }
            }
        });

        if (inputEmail.isConfirmed) {
            if (!isValidEmail(inputEmail.value)) {
                Swal.fire('Error', 'Please enter a valid email address.', 'error');
                return;
            }
            try {
                await sendPasswordReset(inputEmail.value);
                Swal.fire('Email Sent', 'Please check your email for a password reset link.', 'success');
            } catch (error) {
                Swal.fire('Error', error.message, 'error');
            }
        }
    };

    // Redirects to profile settings if the user is already logged in
    useEffect(() => {
        if (user) {
            navigate("/dashboard/profilesettings");
        }
    }, [user, navigate]);

    // Displays tutorial if first-time login
    useEffect(() => {
        const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
        const tutorialShown = localStorage.getItem('tutorialShown') === 'true';

        if (isLoggedIn && !tutorialShown) {
            setTutorialVisible(true);
            localStorage.setItem('tutorialShown', 'true');
        }
    }, [setTutorialVisible]);

    const uniqueId = `login-${Date.now()}`;

    return (
        <AuthContainer>
            {loading && <LoadingSpinner progress={progress} />}
            <h1 className="mx-auto font-roboto text-lg pb-3 text-center text-slate-700 font-bold dark:text-purple-600" style={{ textAlign: 'center', marginTop: '0px' }}>Welcome</h1>
            <form onSubmit={handleFormSubmit}>
                <AuthInput
                    id={`${uniqueId}-email`}
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-mail Address or Username"
                />
                <AuthInput
                    id={`${uniqueId}-password`}
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                />
                <AuthButton
                    id={`${uniqueId}-submit-button`}
                    type="submit"
                    className="login-button mx-auto">
                    Log in
                </AuthButton>
                <button
                    onClick={handleResetPassword}
                    className="text-sm text-slate-600 focus:text-slate-400 my-1">
                    Forgot Password?
                </button>
            </form>
            <p className='!font-roboto flex justify-center my-4'>
                Connect with:
            </p>
            <GoogleButton
                id={`${uniqueId}-google-button`}
                className="mx-auto p-0"
                onClick={handleGoogleLogin}>
                <div className="google-icon-wrapper">
                    <img src="https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg" alt="Google Logo" />
                </div>
                <span className="btn-text mx-auto font-semibold">Sign in with Google</span>
            </GoogleButton>
            <div className="flex justify-center">
                <div className='pt-3'>
                    <span className='font-roboto text-sm text-slate-800'>Don't have an account? </span>
                    <button
                        onClick={switchToRegister}>
                        <span className='text-purple-900 hover:text-purple-800 font-roboto text-sm'>Sign Up</span>
                    </button>
                    <span className='font-roboto text-sm text-slate-800 '> now.</span>
                </div>
            </div>
        </AuthContainer>
    );
}

export default Login;
