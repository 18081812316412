
// AuthModal.jsx
//Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/components/Login/AuthModal.jsx

import React, { useState, useEffect } from 'react';
import Login from './Login.jsx';
import Register from './Register.jsx';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); /* Darker overlay for better contrast */
  z-index: 1000;
`;

const ModalContent = styled.div`
  width: 90vw;
  max-width: 450px;
  height: auto;
  max-height: 85vh;
  min-height: 85vh;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 16px; /* Increased border radius for a more modern look */
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2); /* Deeper shadow for depth */
  overflow-y: auto;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform: translateY(0);
  opacity: 1;
  animation: fadeIn 0.5s ease-in-out;

  @media (min-width: 768px) {
    width: 60vw;
  }
  @media (max-width: 400px) {
    padding: 1rem;
  }
`;

const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 2rem;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 1rem;
`;

const TabButton = styled.button`
  font-family: 'Roboto', sans-serif;
  font-weight: ${props => props.$active ? 'bold' : 'bold'};
  border-bottom: ${props => props.$active ? '2px solid #7140DD' : 'none'};
  color: ${props => props.$active ? '#000000' : '#888888'};
  background-color: transparent;
  cursor: pointer;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  transition: color 0.3s, border-color 0.3s;

  &:hover {
    color: #7140DD;
  }

  @media (min-width: 768px) {
    font-size: clamp(1rem, 2vw, 1.25rem);
  }
`;

function AuthModal({ onClose, activeTab: initialActiveTab }) {
  const [activeTab, setActiveTab] = useState(initialActiveTab || 'login');

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  useEffect(() => {
    const handleEscapeKeyPress = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscapeKeyPress);
    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, [onClose]);

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
    // Update the URL search parameter
    const newUrl = new URL(window.location);
    newUrl.searchParams.set('auth', tabName);
    window.history.pushState({}, '', newUrl);
  };

  return (
    <Overlay className='overflow-y-auto mx-auto scroll-smooth no-scrollbar'  onClick={handleOverlayClick}>
      <ModalContent className='overflow-y-auto mx-auto scroll-smooth no-scrollbar'  onClick={e => e.stopPropagation()}>
        <TabsContainer>
          <TabButton className='tracking-tight' $active={activeTab === 'login'} onClick={() => handleTabChange('login')}>Login</TabButton>
          <TabButton className='tracking-tight' $active={activeTab === 'register'} onClick={() => handleTabChange('register')}>Register</TabButton>
        </TabsContainer>

        {activeTab === 'login' && <Login isActive={activeTab === 'login'} onClose={onClose} switchToRegister={() => setActiveTab('register')} />}
        {activeTab === 'register' && <Register isActive={activeTab === 'register'} onClose={onClose} switchToLogin={() => setActiveTab('login')} />}
      </ModalContent>
    </Overlay>
  );
}

export default AuthModal;
