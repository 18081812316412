//InvestDropdown.jsx
//Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/components/navbar/InvestDropdown.jsx

import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';

// Lazy load the icons
const FaDollarSign = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.FaDollarSign })));
const FaChartLine = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.FaChartLine })));
const AiOutlineCalendar = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.AiOutlineCalendar })));

// Store component references in the categories array
const categories = [
    {
        name: 'All Investor Resources',
        href: '/investor-resources',
        icon: FaDollarSign,
    },
    {
        name: 'Rental Property Analysis',
        href: 'https://rent.report/freerentalreport',
        icon: FaChartLine,
    },
    {
        name: 'Short-Term Rentals (coming soon)',
        href: '#',
        icon: AiOutlineCalendar,
    }
];

function InvestDropdown({ closeMobileMenu }) {
    return (
        <Suspense fallback={
  <div
    className="mx-auto w-12 h-12 border-4 border-t-4 border-gray-200 border-t-purple-500 rounded-full animate-spin transition-opacity duration-300 opacity-100"
    role="status"
    aria-label="Loading"
  ></div>
}>
            <div id="invest-dropdown" >
                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                    {categories.map((category, index) => {
                        const IconComponent = category.icon; // Use the component reference
                        return (
                            <li key={index}>
                                <Link
                                    to={category.href}
                                    onClick={closeMobileMenu} // Ensure menu and dropdown close when link is clicked
                                    className="block flex items-center gap-2 px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                    <IconComponent className="w-5 h-5 text-purple-500" aria-hidden="true" /> {/* Dynamically render the icon */}
                                    <span>{category.name}</span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </Suspense>
    );
}

export default InvestDropdown;
