// Register.jsx
// /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/components/Login/Register.jsx

import React, { useState, useEffect, useContext, Suspense } from 'react';
import AuthContext from '../../contexts/AuthContext.js';
import { useNavigate } from "react-router-dom";
import { setDoc, doc, getFirestore, serverTimestamp, getDoc, query, collection, getDocs, where, updateDoc, deleteField } from "firebase/firestore";
import { AuthContainer, AuthInput, AuthButton, GoogleButton } from './SharedAuthStyles.js';
import useProgress from "../hooks/useProgress.js";
import LoadingSpinner from "../loading/LoadingSpinner.jsx";
import Swal from 'sweetalert2';
import { registerWithEmailAndPassword, signInWithGoogle } from "../firebase/Firebase.js";

const FcGoogle = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.FcGoogle })));

const db = getFirestore();

function Register({ switchToLogin }) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const { user } = useContext(AuthContext);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const { progress, loading, startProgress, completeProgress } = useProgress(1500);

    const [isLandlordOrProfessional, setIsLandlordOrProfessional] = useState(false);
    const [userType, setUserType] = useState('buyer');
    const [buyerLocation, setBuyerLocation] = useState('');
    const [sellerLocation, setSellerLocation] = useState('');
    const [sellerCity, setSellerCity] = useState('');

    const navigate = useNavigate();

    // Validates phone number
    const isValidPhoneNumber = (phone) => {
        const regex = /^\d{10}$/;
        return regex.test(phone);
    };

    // Gets placeholder text for location input based on user type
    let locationPlaceholder = "";
    if (userType === 'buyer') {
        locationPlaceholder = "What city or town are you moving to?";
    } else if (userType === 'justLooking') {
        locationPlaceholder = "What's your desired location?";
    }

    // Handles the registration process
    const register = () => {
        startProgress();

        const showToast = (title) => {
            Swal.fire({
                icon: 'error',
                title: title,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000
            });
        };

        if (!name) {
            showToast('Please enter a name.');
            return;
        }
        if (!isValidPhoneNumber(phoneNumber)) {
            showToast('Please enter a valid phone number.');
            return;
        }

        const checkAvailabilityPromises = [
            getDoc(doc(db, "UserAccounts", email)).then((doc) => {
                if (doc.exists()) {
                    throw new Error('Email is already in use.');
                }
            }),
            getDocs(query(collection(db, "UserAccounts"), where("phoneNumber", "==", phoneNumber))).then((querySnapshot) => {
                if (!querySnapshot.empty) {
                    throw new Error('Phone number is already in use.');
                }
            }),
            getDocs(query(collection(db, "UserAccounts"), where("userName", "==", userName))).then((querySnapshot) => {
                if (!querySnapshot.empty) {
                    throw new Error('Username is already in use.');
                }
            }),
        ];

        let savedUserCredential;
        localStorage.setItem('tutorialShown', 'false');

        Promise.all(checkAvailabilityPromises)
            .then(() => {
                return registerWithEmailAndPassword(name, email, password, userName, phoneNumber);
            })
            .then((userCredential) => {
                if (!userCredential || !userCredential.user) {
                    throw new Error("Registration failed, no user returned");
                }
                savedUserCredential = userCredential;

                const userDocRef = doc(db, "UserAccounts", savedUserCredential.user.uid);
                return setDoc(userDocRef, {
                    uid: savedUserCredential.user.uid,
                    authProvider: "email",
                    name: name,
                    phoneNumber: phoneNumber,
                    email: email,
                    userName: userName,
                    isLandlordOrProfessional: isLandlordOrProfessional,
                    joinDate: serverTimestamp(),
                    savedHomes: [],
                    savedSearches: [],
                    recentlyViewedHomes: [],
                    zpid: [],
                    photoURL: [],
                    location: userType === 'buyer' ? buyerLocation : sellerLocation,
                    userType,
                    sellerCity: userType === 'seller' ? sellerCity : null,
                    isTemporaryUser: true
                });
            })
            .then(() => {
                const userDocRef = doc(db, "UserAccounts", savedUserCredential.user.uid);
                return updateDoc(userDocRef, {
                    isTemporaryUser: deleteField()
                });
            })
            .then(() => {
                Swal.fire('Success!', 'Account successfully created! Welcome!', 'success')
                    .then((result) => {
                        if (result.isConfirmed || result.dismiss === Swal.DismissReason.backdrop) {
                            navigate('/dashboard/profilesettings');
                        }
                    });
            })
            .catch(err => {
                console.error("Error during registration:", err);
                let errorMessage = err.message || "An unexpected error occurred.";
                switch (err.code) {
                    case "auth/email-already-in-use":
                        errorMessage = "That email address is already in use by another account.";
                        Swal.fire({
                            title: 'Error!',
                            text: errorMessage,
                            icon: 'error',
                            confirmButtonText: 'Login Instead',
                            showCancelButton: true,
                            cancelButtonText: 'Cancel'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                if (switchToLogin) {
                                    switchToLogin();
                                } else {
                                    navigate('/login');
                                }
                            }
                        });
                        break;
                    case "auth/invalid-email":
                        showToast('Please use a valid email address.');
                        break;
                    case "auth/weak-password":
                        showToast('The password is too weak. Please choose a stronger password.');
                        break;
                    default:
                        Swal.fire('Error!', errorMessage, 'error');
                        break;
                }
            })
            .finally(() => {
                completeProgress();
            });
    };

    useEffect(() => {
        if (user) {
            navigate("/dashboard/profilesettings");
        }
    }, [user, navigate]);

    const uniqueId = `register-${Date.now()}`;

    return (
        <AuthContainer>
            {loading && <LoadingSpinner progress={progress} />}
            <>
                <h2 className="text-center font-bold mb-2 tracking-tight">Create an account.</h2>
                <AuthInput
                    id={`${uniqueId}-full-name`}
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Full Name"
                    name="name"
                />

                <AuthInput
                    id={`${uniqueId}-phone-number`}
                    type="tel"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Phone Number"
                    name="phoneNumber"
                />

                <AuthInput
                    id={`${uniqueId}-email`}
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-mail Address"
                    name="email"
                />

                <AuthInput
                    id={`${uniqueId}-username`}
                    type="text"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="Username"
                    name="userName"
                />

                <AuthInput
                    id={`${uniqueId}-password`}
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Create your password"
                    name="password"
                />
                <div className="space-y-2">
                    <div className="flex flex-col">
                        {['buyer', 'seller', 'justLooking'].map((type) => (
                            <label className="flex items-center cursor-pointer space-x-2" key={type}>
                                <input
                                    className="text-purple-500 hover:ring-2 hover:ring-slate-300 focus:ring-3 focus:ring-slate-400"
                                    type="radio"
                                    value={type}
                                    checked={userType === type}
                                    onChange={() => setUserType(type)}
                                />
                                <span className="text-slate-800">
                                    {type === 'buyer' ? 'Buying' : type === 'seller' ? 'Selling' : 'Just Looking'}
                                </span>
                            </label>
                        ))}
                    </div>

                    {(userType === 'buyer' || userType === 'justLooking') && (
                        <div className="mt-4">
                            <AuthInput
                                id={`${userType}-location`}
                                type="text"
                                value={buyerLocation}
                                onChange={(e) => setBuyerLocation(e.target.value)}
                                placeholder={locationPlaceholder}
                            />
                        </div>
                    )}

                    {userType === 'seller' && (
                        <div className="mt-4 space-y-2">
                            <AuthInput
                                id="seller-location"
                                type="text"
                                value={sellerLocation}
                                onChange={(e) => setSellerLocation(e.target.value)}
                                placeholder="Where are you moving to?"
                            />
                            <AuthInput
                                id="seller-city"
                                type="text"
                                value={sellerCity}
                                onChange={(e) => setSellerCity(e.target.value)}
                                placeholder="Where are you moving from?"
                            />
                        </div>
                    )}
                </div>

                <div className="my-2 pb-2">
                    <input
                        id={`${uniqueId}-isLandLordOrProfessional`}
                        type="checkbox"
                        checked={isLandlordOrProfessional}
                        onChange={() => setIsLandlordOrProfessional(!isLandlordOrProfessional)}
                        className="my-auto mr-2 rounded-full hover:fill-purple-100 focus:text-purple-500 ring-1 !ring-indigo-200"
                    />
                    <label className="text-slate-800">
                        I'm a landlord, investor, or industry professional.
                    </label>
                </div>
                <AuthButton
                    id={`${uniqueId}-submit`}
                    className="mx-auto mt-4" onClick={register}>
                    Submit
                </AuthButton>
                <GoogleButton
                    id="register-google-sign-up"
                    className="mx-auto"
                    onClick={signInWithGoogle}
                >
                    <div className="google-icon-wrapper">
                        <Suspense fallback={<div className="w-5 h-5 animate-spin">Loading...</div>}>
                            <FcGoogle className="w-5 h-5" alt="Google Logo" />
                        </Suspense>
                    </div>
                    <span className="btn-text mx-auto">Sign up with Google</span>
                </GoogleButton>

                <div className="py-2 text-center">
                    <div>
                        Already have an account?
                        <button
                            id={`${uniqueId}-switchToSignIn`}
                            className="text-purple-700 focus:text-purple-500 ml-1"
                            onClick={switchToLogin}
                        >
                            Sign in
                        </button>
                    </div>
                </div>
            </>
        </AuthContainer>
    );
}

export default Register;
