//urlUtils.js
//Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/utils/urlUtils.js
// src/utils/urlUtils.js

import { ALL_FILTER_PARAMS } from "../constants/filterParams";

export const updateUrlWithSearchParams = (
  updatedFilters,
  currentSearchQuery,
  currentSearchType,
  polygonCoords,
  zpid,
  filters
) => {
  const searchParams = new URLSearchParams(window.location.search);
  const finalFilters = { ...filters, ...updatedFilters };

  // Handle search type specific parameters
  if (currentSearchType === 'polygon') {
    if (Array.isArray(polygonCoords) && polygonCoords.length > 0) {
      const formattedPolygonCoords = polygonCoords.map(([lng, lat]) => `${lng} ${lat}`).join(",");
      finalFilters.polygon = formattedPolygonCoords;
      delete finalFilters.location;
    } else {
      console.warn('polygonCoords is not an array or is empty:', polygonCoords);
      // Optionally, you can decide to remove the polygon filter if invalid
      delete finalFilters.polygon;
      delete finalFilters.location;
    }
  } else if (currentSearchType === 'location') {
    finalFilters.location = currentSearchQuery;
    delete finalFilters.polygon;
  }

  // Remove irrelevant parameters
  delete finalFilters.zpid;
  delete finalFilters.address;

  // Generate new URL based on filters
  const filterParams = generateFilterParams(finalFilters);
  filterParams.forEach((value, key) => {
    searchParams.set(key, value);
  });

  // Remove any filter parameters not present in finalFilters
  ALL_FILTER_PARAMS.forEach(param => {
    if (!finalFilters.hasOwnProperty(param)) {
      searchParams.delete(param);
    }
  });

  // Handle zpid (include it only if valid)
  if (zpid && typeof zpid === 'string' && zpid !== 'undefined') {
    searchParams.set('zpid', zpid);
  } else {
    searchParams.delete('zpid');
  }

  const newUrl = `/listings?${searchParams.toString()}`;

  // Update the history state with a properly formatted relative URL
  window.history.replaceState({}, "", newUrl);

  return newUrl;
};

// ... rest of the file remains unchanged


export const generateUrlWithFilters = (basePath, filters) => {
  const searchParams = new URLSearchParams();

  // Remove old parameters related to modal views
  searchParams.delete('zpid');
  searchParams.delete('address');

  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      searchParams.set(key, value);
    }
  });

  // Check if the basePath is a full URL or a relative path
  const generatedUrl = basePath.startsWith("http")
    ? `${basePath.split("?")[0]}?${searchParams.toString()}`
    : `${basePath}?${searchParams.toString()}`;

  return generatedUrl;
};


export const updateUrlParameter = (url, param, value) => {
  const urlObj = new URL(url);
  /*   console.log("Updating URL parameter:");
    console.log("URL before update:", url);
    console.log("Parameter to update:", param);
    console.log("New value:", value);
   */
  urlObj.searchParams.set(param, value);
  const updatedUrl = urlObj.toString();
  /*   console.log("Updated URL:", updatedUrl); */
  return updatedUrl;
};

/**
 * Generates query parameters for filters.
 * @param {Object} filters - The filters object containing various filter parameters.
 * @returns {URLSearchParams} - The URLSearchParams object with filter parameters.
 */
export const generateFilterParams = (filters) => {
  const searchParams = new URLSearchParams();

  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      searchParams.set(key, value);
    }
  });

  return searchParams;
};
