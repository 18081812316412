
//AuthContext.js
//Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/contexts/AuthContext.js

import { createContext } from 'react';

const AuthContext = createContext({
    user: null,
    isAuthenticated: false,
    setUser: () => {},
    updateUserName: () => {},
    updateUser: () => {}, // Placeholder for the updateUser function

});

export default AuthContext;