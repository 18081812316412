// ErrorBoundary.jsx
// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/utils/ErrorBoundary.jsx
import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  handleNonCriticalErrors(error) {
    if (
      error.message.includes('Map container is already initialized') ||
      error.message.includes('Network Error')
    ) {
      console.warn('Non-critical error caught and ignored');
      toast.warn('Non-critical error occurred. Some functionalities may not work as expected.');
      this.setState({ hasError: false, error: null });
      return true;
    }
    return false;
  }

  componentDidCatch(error, errorInfo) {
    if (this.handleNonCriticalErrors(error)) {
      return;
    }

    console.error("ErrorBoundary caught an error", error, errorInfo);
    Sentry.captureException(error, { extra: errorInfo });

    // Check if a reload has been attempted already
    const hasReloaded = sessionStorage.getItem('hasReloaded');

    if (!hasReloaded) {
      // Mark that we've attempted a reload
      sessionStorage.setItem('hasReloaded', 'true');
      // Reload the page
      window.location.reload();
      return;
    }

    // Set error state if reload did not solve the problem
    this.setState({ hasError: true, error });
  }

  render() {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return this.props.fallback;
      }
      if (this.state.error && this.state.error.message.includes('Map')) {
        return (
          <div className="bg-yellow-100 text-yellow-700 p-4">
            <h2>Map initialization error occurred, but you can continue using the site.</h2>
          </div>
        );
      }
      return (
        <div className="flex flex-col items-center justify-center h-screen bg-purple-800/60 text-white text-center p-12 m-1 rounded-lg animate-fadeIn shadow-lg">
          <div className="bg-purple-900/70 p-10 rounded-lg shadow-lg max-w-2xl">
            <div className="flex flex-col items-center justify-center ">
              <img 
                src="https://www.spearrealestategroup.com/assets/icecream.gif" 
                alt="Icecream" 
                className="mb-6 w-48 h-48 object-contain ml-7" 
              />
              <span className="text-5xl mb-6 font-extraBold align-bottom">Oops!</span>
            </div>
            <h1 className="text-5xl mb-6 font-extraBold">
              Something went wrong, but we're working on it!
            </h1>
            <p className="text-lg mb-8 leading-7"> 
              Please reload the page and try again. If the issue persists, contact <a href="mailto:support@spearrealestategroup.homes" className="text-blue-400 underline">support@spearrealestategroup.homes</a> for assistance.
            </p>
            <div className='flex flex-row w-full gap-2 mx-auto justify-center items-center'>
              <button 
                onClick={() => window.location.reload()} 
                className="mt-6 px-4 py-2 bg-orange-500 hover:bg-orange-600 text-white font-bold rounded-lg transition-transform transform hover:scale-105"
              >
                Reload
              </button>
              <button 
                onClick={() => this.setState({ hasError: false, error: null })} 
                className="mt-6 px-4 py-2 bg-gray-500 hover:bg-gray-600 text-white font-bold rounded-lg transition-transform transform hover:scale-105"
              >
                Dismiss
              </button>
            </div>  
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
