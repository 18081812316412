// SharedAuthStyles.js
// /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/components/Login/SharedAuthStyles.js
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Container for the authentication forms
export const AuthContainer = styled.div`
  padding: 2rem;
  background-color: #f9f9f9; // Updated to a softer light theme
  border-radius: 16px; // Increased for a more modern look
  width: 100%;
  max-width: 500px; 
  margin: auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); // Added a subtle shadow for depth
`;

export const AuthInput = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 1.25rem;
  border-radius: 8px; // Softer rounded corners
  border: 1px solid #d3d4d5; // Lighter border for a cleaner look
  font-size: 1rem;
  transition: border-color 0.3s, box-shadow 0.3s;
  
  &:focus {
    border-color: #5D3AB1;
    outline: none;
    box-shadow: 0 0 0 4px rgba(93, 58, 177, 0.2); // Slightly increased shadow for focus
  }
`;

// Button styling for login and Google sign-in
export const AuthButton = styled.button`
  width: 100%;
  padding: 12px 0;
  background: linear-gradient(90deg, #5D3AB1 0%, #7140DD 100%); // Added gradient for more modern style
  color: #fff;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  margin-bottom: 1.25rem;
  font-weight: bold;
  font-size: 1rem;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background-color: #4b2c9c;
    transform: translateY(-2px); // Slight lift effect on hover
  }

  &:active {
    background-color: #3a2379;
    transform: translateY(1px); // Depress effect on click
  }
`;

export const AuthLink = styled(Link)`
  text-decoration: none;
  color: #5D3AB1;
  font-weight: 500;
  transition: color 0.3s;

  &:hover {
    text-decoration: underline;
    color: #7140DD; // Slightly lighter shade for hover
  }
`;

const GoogleBlue = "#FFFFFF";

export const GoogleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  max-width: 400px;
  width: 100%;
  height: 50px;
  min-width: min-content;
  background-color: ${GoogleBlue};
  border-radius: 16px; // Increased for a more modern look
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); // Enhanced shadow for more depth
  margin-bottom: 1.5rem;
  padding-left: 1rem;
  border: 1px solid #e0e0e0;
  transition: box-shadow 0.3s, transform 0.3s;

  &:hover {
    box-shadow: 0 0 12px #1669F2;
    transform: translateY(-2px); // Lift effect on hover
  }

  &:active {
    background: #1669F2;
    transform: translateY(1px); // Depress effect on click
  }

  .google-icon-wrapper {
    background-color: transparent;
    border-radius: 50%;
    margin-right: 8px;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .btn-text {
    color: #3c4043;
    font-size: 1rem;
    letter-spacing: 0.2px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
  }
`;

// Additional styling suggestions
export const Divider = styled.div`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
  line-height: 0.1em;
  margin: 1.5rem 0;
  color: #777;

  &::before {
    content: "";
    display: inline-block;
    width: 25%;
    border-bottom: 1px solid #e0e0e0;
    vertical-align: middle;
    margin-right: 0.5em;
  }

  &::after {
    content: "";
    display: inline-block;
    width: 25%;
    border-bottom: 1px solid #e0e0e0;
    vertical-align: middle;
    margin-left: 0.5em;
  }
`;
