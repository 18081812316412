// cache.js
//Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/utils/cache.js

// cache.js
// Path: /Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/utils/cache.js

// Cache keys and initializations
export const CACHE_KEY = 'propertyDetailCache';
export const SIMILAR_PROPERTIES_CACHE_KEY = 'similarPropertiesCache';
export const IMAGE_CACHE_KEY = 'imageCache';
export const POLYGON_SEARCH_CACHE_KEY = 'polygonSearchCache'; // Define a key for the polygon search cache

export let propertyDetailCache = JSON.parse(localStorage.getItem(CACHE_KEY)) || {};
export let similarPropertiesCache = JSON.parse(localStorage.getItem(SIMILAR_PROPERTIES_CACHE_KEY)) || {};
export let imageCache = JSON.parse(localStorage.getItem(IMAGE_CACHE_KEY)) || {};
export let polygonSearchCache = JSON.parse(localStorage.getItem(POLYGON_SEARCH_CACHE_KEY)) || {}; // Initialize the polygon cache

// Cache settings
export const cacheDuration = 7 * 24 * 60 * 60 * 1000; // 1 week
export const cacheTimeout = 3000000; // 5 minutes 

// Helper functions
export const isCacheExpired = (expiry) => {
  const currentTime = Date.now();
  // Uncomment for debugging
  // console.log(`Checking if cache expired. Expiry time: ${expiry}, Current time: ${currentTime}, Expired: ${currentTime > expiry}`);
  return currentTime > expiry;
};

// Utility function to generate a unique cache key based on polygonCoords and filters
export const generateCacheKey = (polygonCoords, effectiveFilters) => {
  if (!polygonCoords || !effectiveFilters) {
    console.warn("Invalid polygonCoords or effectiveFilters provided to generateCacheKey.");
    return null;
  }

  // Select relevant filters to include in the cache key
  const relevantFilters = {
    sort: effectiveFilters.sort,
    status_type: effectiveFilters.status_type,
    minPrice: effectiveFilters.minPrice,
    maxPrice: effectiveFilters.maxPrice,
    bedsMin: effectiveFilters.bedsMin,
    bedsMax: effectiveFilters.bedsMax,
    bathsMin: effectiveFilters.bathsMin,
    bathsMax: effectiveFilters.bathsMax,
    // Add other filters as needed
  };

  // Serialize the filters in a consistent order
  const serializedFilters = Object.keys(relevantFilters)
    .sort()
    .map(key => `${key}:${relevantFilters[key]}`)
    .join('|');

  return `${polygonCoords}|${serializedFilters}`;
};

export const clearCache = () => {
  propertyDetailCache = {};
  similarPropertiesCache = {};
  imageCache = {};
  polygonSearchCache = {}; // Clear polygon search cache
  localStorage.removeItem(CACHE_KEY);
  localStorage.removeItem(SIMILAR_PROPERTIES_CACHE_KEY);
  localStorage.removeItem(IMAGE_CACHE_KEY);
  localStorage.removeItem(POLYGON_SEARCH_CACHE_KEY); // Remove polygon search cache from localStorage
};


const updateCache = () => {
  try {
    // Uncomment for debugging
    /*
    console.log('Updating cache in localStorage:', {
      propertyDetailCache,
      similarPropertiesCache,
      imageCache,
      polygonSearchCache, // Include polygon search cache here
    });
    */
    localStorage.setItem(CACHE_KEY, JSON.stringify(propertyDetailCache));
    localStorage.setItem(SIMILAR_PROPERTIES_CACHE_KEY, JSON.stringify(similarPropertiesCache));
    localStorage.setItem(IMAGE_CACHE_KEY, JSON.stringify(imageCache));
    localStorage.setItem(POLYGON_SEARCH_CACHE_KEY, JSON.stringify(polygonSearchCache)); // Store polygon search cache
    // Uncomment for debugging
    // console.log('Cache updated successfully in localStorage.');
  } catch (error) {
    console.error('Failed to update cache in localStorage:', error);
  }
};

// Normalization functions
export const normalizeKey = (key) => {
  return String(key).toLowerCase().trim();  // Normalize key consistently
};

export const normalizeAddressKey = (address) => {
  return `${address.streetAddress} ${address.city} ${address.state} ${address.zipcode} usa`.toLowerCase().trim();
};

// Property Detail Cache
export const setCachedPropertyDetail = (key, data) => {
  key = normalizeKey(key); // Normalize key

  // Uncomment for debugging
  // console.log(`Setting cache for key: ${key} with data:`, data);

  // Cache the data using both address and zpid
  propertyDetailCache[key] = {
    data,
    timestamp: Date.now(),
  };

  // If the data contains a zpid, cache it under the zpid key as well
  if (data.zpid) {
    const zpidKey = normalizeKey(data.zpid);
    propertyDetailCache[zpidKey] = {
      data,
      timestamp: Date.now(),
    };
    // Uncomment for debugging
    // console.log(`Cache updated for ZPID key: ${zpidKey}`);
  }

  updateCache(); // Sync with localStorage or other storage mechanisms
};

export const getCachedPropertyDetail = (key) => {
  key = normalizeKey(key);  // Normalize key

  // Uncomment for debugging
  // console.log(`Attempting cache read for key: ${key}`);
  const cachedDetail = propertyDetailCache[key];

  if (cachedDetail) {
    const isExpired = isCacheExpired(cachedDetail.timestamp + cacheTimeout);
    // Uncomment for debugging
    // console.log(`Cache read for key: ${key}, Data:`, cachedDetail, `Expired: ${isExpired}`);

    if (!isExpired) {
      // Uncomment for debugging
      // console.log(`Cache hit for key: ${key}`);
      return cachedDetail.data;
    } else {
      // Optionally remove expired cache
      delete propertyDetailCache[key];
      updateCache();
    }
  }

  // Uncomment for debugging
  // console.log(`Cache miss or expired for key: ${key}`);
  return null;
};

// Polygon Cache with Filters
export const setCachedPolygonSearch = (polygonCoords, effectiveFilters, data) => {
  if (!polygonCoords || !effectiveFilters) {
    console.warn("Invalid polygonCoords or effectiveFilters provided to setCachedPolygonSearch.");
    return;
  }

  const cacheKey = generateCacheKey(polygonCoords, effectiveFilters);
  if (!cacheKey) {
    console.warn("Failed to generate cacheKey for polygon search.");
    return;
  }

  // Proceed if the key is valid
  polygonSearchCache[cacheKey] = {
    data,
    timestamp: Date.now(),
  };

  updateCache(); // Sync with localStorage or other storage mechanisms
};

// Retrieve from polygon cache
export const getCachedPolygonSearch = (polygonCoords, effectiveFilters) => {
  if (!polygonCoords || !effectiveFilters) {
    console.warn("Invalid polygonCoords or effectiveFilters provided to getCachedPolygonSearch.");
    return null;
  }

  const cacheKey = generateCacheKey(polygonCoords, effectiveFilters);
  if (!cacheKey) {
    console.warn("Failed to generate cacheKey for polygon search.");
    return null;
  }

  // Uncomment for debugging
  // console.log(`Attempting cache read for polygon key: ${cacheKey}`);
  const cachedPolygon = polygonSearchCache[cacheKey]; // Read from the correct cache

  if (cachedPolygon) {
    const isExpired = isCacheExpired(cachedPolygon.timestamp + cacheTimeout);
    // Uncomment for debugging
    // console.log(`Cache read for key: ${cacheKey}, Data:`, cachedPolygon, `Expired: ${isExpired}`);

    if (!isExpired) {
      // Uncomment for debugging
      // console.log(`Polygon cache hit for key: ${cacheKey}, with data:`, cachedPolygon.data);
      return cachedPolygon.data;
    } else {
      // Optionally remove expired cache
      delete polygonSearchCache[cacheKey];
      updateCache();
    }
  }

  // Uncomment for debugging
  // console.log(`Cache miss or expired for polygon key: ${cacheKey}`);
  return null;
};

// Similar Properties Cache
export const setCachedSimilarProperties = (key, data) => {
  key = normalizeKey(key); // Normalize the key

  // Uncomment for debugging
  // console.log(`Setting cache for similar properties key: ${key} with data:`, data);

  similarPropertiesCache[key] = {
    data,
    timestamp: Date.now(), // Store the current timestamp
  };

  updateCache(); // Sync with localStorage or other storage mechanisms
};

export const getCachedSimilarProperties = (key) => {
  key = normalizeKey(key);  // Normalize key

  // Uncomment for debugging
  // console.log(`Attempting cache read for similar properties key: ${key}`);
  const cachedSimilarProperties = similarPropertiesCache[key];

  if (cachedSimilarProperties) {
    const isExpired = isCacheExpired(cachedSimilarProperties.timestamp + cacheTimeout);
    // Uncomment for debugging
    // console.log(`Cache read for similar properties key: ${key}, Data:`, cachedSimilarProperties, `Expired: ${isExpired}`);

    if (!isExpired) {
      // Uncomment for debugging
      // console.log(`Similar properties cache hit for key: ${key}`);
      return cachedSimilarProperties.data;
    } else {
      // Optionally remove expired cache
      delete similarPropertiesCache[key];
      updateCache();
    }
  }

  // Uncomment for debugging
  // console.log(`Cache miss or expired for similar properties key: ${key}`);
  return null;
};

// Image Cache
export const setCachedImages = (key, data) => {
  key = normalizeKey(key); // Normalize the key to ensure consistency

  // Uncomment for debugging
  // console.log(`Setting cache for image key: ${key} with data:`, data);

  imageCache[key] = {
    data,
    timestamp: Date.now(), // Store the current timestamp
  };

  updateCache(); // Sync with localStorage or other storage mechanisms
};

export const getCachedImages = (key) => {
  key = normalizeKey(key); // Normalize the key

  // Uncomment for debugging
  // console.log(`Attempting cache read for image key: ${key}`);
  const cachedImages = imageCache[key];

  if (cachedImages) {
    const isExpired = isCacheExpired(cachedImages.timestamp + cacheTimeout);
    // Uncomment for debugging
    // console.log(`Cache read for image key: ${key}, Data:`, cachedImages, `Expired: ${isExpired}`);

    if (!isExpired) {
      // Uncomment for debugging
      // console.log(`Image cache hit for key: ${key}`);
      return cachedImages.data;
    } else {
      // Optionally remove expired cache
      delete imageCache[key];
      updateCache();
    }
  }

  // Uncomment for debugging
  // console.log(`Cache miss or expired for image key: ${key}`);
  return null;
};
