//RentDropdown.jsx
//Path:/Users/mackspear/SREG Property Finder/SREGPropertyFinder/src/components/navbar/RentDropdown.jsx

import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';

// Lazy load all icons
const MdLibraryBooks = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.MdLibraryBooks })));
const FaHome = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.FaHome })));
const FaHouseUser = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.FaHouseUser })));
const BiBuildingHouse = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.BiBuildingHouse })));
const AiOutlineFileText = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.AiOutlineFileText })));
const AiOutlineCalculator = React.lazy(() => import('../../assets/svgIcons/svgIcons.js').then(module => ({ default: module.AiOutlineCalculator })));

// Define categories array outside the component to prevent re-creation on every render
const categories = [
    {
        name: 'All Renter Resources',
        href: '/renter-resources',
        icon: MdLibraryBooks,
    },
    {
        name: 'All Rental Properties',
        href: '/listings?status_type=ForRent&location=Eugene%2C+OR%2C+USA',
        icon: FaHome,
    },
    {
        name: 'Apartments for Rent',
        href: '/listings?status_type=ForRent&home_type=Apartments_Condos_Co-ops&location=Eugene%2C+OR%2C+USA',
        icon: BiBuildingHouse,
    },
    {
        name: 'Houses for Rent',
        href: '/listings?location=Eugene%2C+OR%2C+USA&page=1&status_type=ForRent&home_type=Houses',
        icon: FaHouseUser,
    },
    {
        name: 'Free Rental Forms',
        href: '/contact',
        icon: AiOutlineFileText,
    },
    {
        name: 'Affordability Calculator',
        href: '/investment-calculators',
        icon: AiOutlineCalculator,
    },
];

function RentDropdown({ closeMobileMenu }) {
    return (
        <Suspense fallback={
  <div
    className="mx-auto w-12 h-12 border-4 border-t-4 border-gray-200 border-t-purple-500 rounded-full animate-spin transition-opacity duration-300 opacity-100"
    role="status"
    aria-label="Loading"
  ></div>
}>
            <div id="rent-dropdown" >
                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                    {categories.map((category, index) => {
                        const IconComponent = category.icon; // Use the component reference
                        return (
                            <li key={index}>
                                <Link
                                    to={category.href}
                                    onClick={closeMobileMenu} // Ensure menu and dropdown close when link is clicked
                                    className="block flex items-center gap-2 px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                    <IconComponent className="w-5 h-5 text-purple-500" aria-hidden="true" /> {/* Dynamically render the icon */}
                                    <span>{category.name}</span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </Suspense>
    );
}

export default RentDropdown;
